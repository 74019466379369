import {Avatar, Table, TableBody, TableHead, TableRow} from "@material-ui/core";
import moment from "moment";
import React, {PropsWithChildren, useCallback, useEffect, useState} from "react";
import LoaderComponent from "../../../../components/LoaderComponent";
import {ENV} from "../../../../constants";
import {CommonService} from "../../../../helpers";
import "./PendingShiftsViewComponent.scss";

export interface PendingShiftsViewComponentProps {
    cancel: () => void;
    confirm: () => void;
    requirementId: string;
    hcpId: string;
    applicationId: string;
}

const PendingShiftsViewComponent = (props: PropsWithChildren<PendingShiftsViewComponentProps>) => {
    const applicationId = props?.applicationId;
    const [basicDetails, setBasicDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const init = useCallback(() => {
        setIsLoading(true);
        CommonService._api
            .get(ENV.API_URL + `application/${applicationId}`)
            .then((resp) => {
                setBasicDetails(resp?.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [applicationId]);

    useEffect(() => {
        init();
    }, [init]);

    const {start_time, end_time} = CommonService.getUtcTimeInAMPM(
        basicDetails?.requirement?.shift_timings?.start_time,
        basicDetails?.requirement?.shift_timings?.end_time
    );
    const shift_date = CommonService.getUtcDate(basicDetails?.shift_date);


    const address = [
        basicDetails?.requirement?.patient_details?.address?.apt,
        basicDetails?.requirement?.patient_details?.address?.street,
        basicDetails?.requirement?.patient_details?.address?.city,
        basicDetails?.requirement?.patient_details?.address?.state,
        basicDetails?.requirement?.patient_details?.address?.zip_code
    ].filter(Boolean).join(', ') || '-';

    if (isLoading) {
        return (
            <div className="pending-shiftsrequirment-view screen pdd-100">
                <LoaderComponent position="block"/>
            </div>
        );
    }

    return (
        <div className="pending-shiftsrequirment-view screen crud-layout pdd-50">
            {!isLoading && (
                <div>
                    <div className="d-flex">
                        <h2 className="status-title">
                            Status&nbsp;:&nbsp;<span className="status">{basicDetails?.status}</span>
                        </h2>
                    </div>
                    {basicDetails?.status === "awaiting" ? (
                        <div className="pdd-0 custom-border pdd-20">
                            <Table className="">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        <th>Staff</th>
                                        <th>Role</th>
                                        <th>Date and time</th>
                                        <th>Action</th>
                                        <th>Last Staff</th>
                                        <th>Last Staff Role</th>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {basicDetails?.application_history?.takeover_details
                                        ?.slice()
                                        .reverse()
                                        .map((item: any, index: any) => {
                                            return (
                                                <TableRow className={"mat-tr"}>
                                                    <td>
                                                        {item?.first_name}&nbsp;{item?.last_name}
                                                    </td>
                                                    <td>{item?.role}</td>
                                                    <td>
                                                        {moment(item?.date).format("MM-DD-YYYY")},
                                                        {moment(moment.utc(item?.date).toDate()).local().format("hh:mm:ss A")}
                                                    </td>
                                                    <td>TakeOver Request</td>
                                                    <td>
                                                        {index === 0
                                                            ? `${basicDetails?.awaited_by?.first_name} ${basicDetails?.awaited_by?.last_name}`
                                                            : `${basicDetails?.application_history?.takeover_details[index - 1]?.first_name} ${
                                                                basicDetails?.application_history?.takeover_details[index - 1]?.last_name
                                                            }`}
                                                    </td>
                                                    <td>
                                                        {index === 0
                                                            ? `${basicDetails?.awaited_by?.role}`
                                                            : `${basicDetails?.application_history?.takeover_details[index - 1]?.role}`}
                                                    </td>
                                                </TableRow>
                                            );
                                        })}
                                    <TableRow className={"mat-tr"}>
                                        <td>
                                            {basicDetails?.awaited_by?.first_name}&nbsp;
                                            {basicDetails?.awaited_by?.last_name}
                                        </td>
                                        <td>{basicDetails?.awaited_by?.role}</td>
                                        <td>
                                            {moment(basicDetails?.awaited_by?.date).format("MM-DD-YYYY")},
                                            {moment(moment.utc(basicDetails?.awaited_by?.date).toDate())
                                                .local()
                                                .format("hh:mm:ss A")}
                                        </td>
                                        <td>Awaiting Request</td>
                                        <td>--</td>
                                        <td>--</td>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="mrg-top-10 pdd-0 custom-border">
                        <div className="d-flex pdd-20 hcp-photo-details-wrapper">
                            <div className="d-flex">
                                <div className="flex-1">
                                    <Avatar style={{height: "80px", width: "80px"}}>
                                        {basicDetails?.hcp?.first_name?.toUpperCase().charAt("0")}
                                    </Avatar>
                                </div>
                                <div className="hcp-name-type mrg-left-40">
                                    <h2>
                                        {basicDetails?.hcp?.first_name}&nbsp;{basicDetails?.hcp?.last_name}
                                    </h2>
                                    <p>{basicDetails?.hcp?.hcp_type}</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex hcp-details pdd-bottom-20 custom-border" style={{gap: "20px"}}>
                            <div className="flex-1">
                                <h4>Years Of Experience</h4>
                                <p>
                                    {" "}
                                    {basicDetails?.hcp?.professional_details?.experience
                                        ? basicDetails?.hcp?.professional_details?.experience + " Years"
                                        : basicDetails?.hcp?.professional_details?.experience === 0
                                            ? 0 + " Years"
                                            : "N/A"}
                                </p>
                            </div>
                            <div className="flex-1">
                                <h4>Contact Number</h4>
                                <p>{basicDetails?.hcp?.contact_number}</p>
                            </div>
                            <div className="flex-1">
                                <h4>Address</h4>
                                <p>
                                    {basicDetails?.hcp?.address?.region},&nbsp;{basicDetails?.hcp?.address?.city},&nbsp;
                                    {basicDetails?.hcp?.address?.state},&nbsp;{basicDetails?.hcp?.address?.country},&nbsp;
                                    {basicDetails?.hcp?.address?.zip_code}&nbsp;&nbsp;
                                </p>
                            </div>
                            <div className="flex-1">
                                <h4>Email</h4>
                                <p>{basicDetails?.hcp?.email}</p>
                            </div>
                        </div>
                    </div>

                    {basicDetails?.requirement?.type === "home" &&
                        <div className="facility-details custom-border mrg-top-10">
                            <div className={'d-flex'}>
                                {basicDetails && basicDetails?.requirement?.patient_details?.first_name &&
                                    <h3 className={'mrg-right-5'}>{basicDetails?.requirement?.patient_details?.first_name}</h3>}
                                {basicDetails && basicDetails?.requirement?.patient_details?.middle_name &&
                                    <h3 className={'mrg-right-5'}>{basicDetails?.requirement?.patient_details?.middle_name}</h3>}
                                {basicDetails && basicDetails?.requirement?.patient_details?.last_name &&
                                    <h3>{basicDetails?.requirement?.patient_details?.last_name}</h3>}
                                {
                                    <h3 className={"mrg-left-10"}>
                                        ({basicDetails && CommonService.calculateAge(basicDetails?.requirement?.patient_details?.dob)}years, {basicDetails && basicDetails?.requirement?.patient_details?.gender})
                                    </h3>
                                }
                            </div>
                            <p>
                                {address}
                            </p>
                            <p>
                                MRN no : {basicDetails && basicDetails?.requirement?.patient_details?.mrn}
                            </p>

                            <p>
                                Latitude
                                : {basicDetails?.requirement?.patient_details?.location?.coordinates ? basicDetails?.requirement?.patient_details?.location?.coordinates[1] : ""}
                            </p>
                            <p>
                                Longitude
                                : {basicDetails?.requirement?.patient_details?.location?.coordinates ? basicDetails?.requirement?.patient_details?.location?.coordinates[0] : ""}
                            </p>

                            <div className={'mrg-top-25 d-flex'} style={{
                                color: "#808080"
                            }}>
                                <div>
                                    <span>Phone Number : {basicDetails && basicDetails?.requirement?.patient_details?.contact_number}</span>
                                </div>

                                <div className={'d-flex'}>
                                    <span>&nbsp;| Email Address : {basicDetails && basicDetails?.requirement?.patient_details?.email}</span>
                                </div>

                            </div>
                        </div>}


                    <div className="facility-details mrg-top-10 custom-border">
                        <div className="d-flex shift-name-requested">
                            <h2>Shift Details</h2>
                            <div className="d-flex requested-on-wrapper">
                                <h3>Created On:</h3>
                                <p className="mrg-left-10">
                                    {moment(basicDetails?.created_at).format("MM-DD-YYYY")}
                                </p>
                            </div>
                        </div>

                        <p className="mrg-top-0">{basicDetails?.requirement?.title}</p>


                        {basicDetails?.requirement?.type === "home" &&
                            <>
                                <p>{basicDetails?.title}</p>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Visit Patient By Date</h3>
                                        <p>{shift_date}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Shift Time</h3>
                                        <p>
                                            {/*{start_time}*/}
                                            {/*&nbsp;-&nbsp;*/}
                                            {start_time} - {end_time}
                                        </p>
                                    </div>

                                    <div className="flex-1">
                                        <h3>Visit Type</h3>
                                        <p>{basicDetails?.shift_type}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Visit Patient By Time</h3>
                                        <p>
                                            {/*{start_time} &nbsp;-&nbsp;*/}
                                            {end_time}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <>
                                            <h3>Total Number of Visits</h3>
                                            <p>{basicDetails?.requirement?.home_requirements?.number_of_visits ? (basicDetails?.requirement?.home_requirements?.number_of_visits) : "NA"}</p>
                                        </>
                                    </div>
                                    <div className="flex-1">
                                        <>
                                            <h3>Type</h3>
                                            <p>Per Job/Visit</p>
                                        </>
                                    </div>
                                    <div className="flex-1">
                                        <>
                                            <h3>Language</h3>
                                            <p>{basicDetails?.requirement?.home_requirements?.languages ? basicDetails?.requirement?.home_requirements?.languages?.filter((language: any) => language !== null)
                                                .join(', ') : "NA"}</p>
                                        </>
                                    </div>
                                    <div className="flex-1">
                                        <>
                                            <h3>Gender Preference</h3>
                                            <p>{basicDetails?.requirement?.home_requirements?.gender_pref ? CommonService.capitalizeFirstLetter(basicDetails?.requirement?.home_requirements?.gender_pref) : "NA"}</p>
                                        </>
                                    </div>
                                </div>


                            </>
                        }
                        {basicDetails?.requirement?.type !== "home" && basicDetails?.requirement?.type !== "contract" &&
                            <>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Required On</h3>
                                        <p>{shift_date}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Time</h3>
                                        <p>
                                            {start_time} &nbsp;-&nbsp;{end_time}
                                        </p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>No. Of Staff</h3>
                                        <p> {basicDetails?.requirement?.hcp_count}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Shift Type</h3>
                                        <p>{basicDetails?.shift_type}</p>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Hcp Type</h3>
                                        <p>{basicDetails?.hcp?.hcp_type}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h3>Warning Zone</h3>
                                        <p>{basicDetails?.requirement?.warning_type}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h3>Staff Speciality</h3>
                                        <p>{basicDetails?.hcp?.professional_details?.speciality ? basicDetails?.hcp?.professional_details?.speciality ?? "NA" : "NA"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Staff Sent Home</h3>
                                        <p>
                                            {basicDetails?.hasOwnProperty("is_sent_home")
                                                ? basicDetails?.is_sent_home === true
                                                    ? "Yes"
                                                    : "No"
                                                : "NA"}
                                        </p>
                                    </div>

                                </div>
                            </>}

                        {basicDetails?.requirement?.type === "contract" &&
                            <>
                                <p>{basicDetails?.title}</p>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Shift Date</h3>
                                        <p>{CommonService.getUtcDate(basicDetails?.shift_date)}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Shift Duration</h3>
                                        <p>
                                            {basicDetails?.requirement?.contract_requirements?.shift_duration ? basicDetails?.requirement?.contract_requirements?.shift_duration + "h" : "NA"}
                                        </p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Hcp Type</h3>
                                        <p>{basicDetails?.hcp?.hcp_type || "-"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Shift Type</h3>
                                        <p>{basicDetails?.shift_type || "NA"}</p>
                                    </div>

                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Job Type</h3>
                                        <p>{basicDetails?.requirement?.contract_requirements?.contract_type || "-"}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Days Per Week</h3>
                                        <p>{basicDetails?.requirement?.contract_requirements?.days_per_week || 0}</p>
                                    </div>

                                    <div className="flex-1">
                                        <h3>Open Positions</h3>
                                        <p>{basicDetails?.requirement?.hcp_count || 0}</p>
                                    </div>
                                    <div className="flex-1">
                                        <h3>Number of weeks</h3>
                                        <p> {basicDetails?.requirement?.contract_requirements?.no_of_weeks || "-"}</p>
                                    </div>
                                </div>

                                <div className="d-flex shift-details">
                                    <div className="flex-1">
                                        <h3>Shift Date Range</h3>
                                        <p>{(CommonService.formatDateTimeStamp(basicDetails?.requirement?.shift_timings?.start_time) + " -> " + CommonService.formatDateTimeStamp(basicDetails?.requirement?.shift_timings?.end_time)) || "-"}</p>
                                    </div>
                                </div>

                                <div className=" shift-details">
                                    <h3>Travel Diem</h3>
                                    <div>
                                        {
                                            Array.isArray(basicDetails?.requirement?.contract_requirements?.travel_per_diems) &&
                                            basicDetails?.requirement?.contract_requirements?.travel_per_diems?.length > 0 ? (
                                                basicDetails.requirement.contract_requirements.travel_per_diems.map((item: any, index: any) => {
                                                    const entries = Object.entries(item);
                                                    const [key, value]: any = entries[0] || ['', 0];
                                                    const [key2, valuetwo]: any = entries[1] || ['', 0];

                                                    return (
                                                        <div key={index} className="mrg-bottom-10">
                                                            <div className="d-flex">
                                                                <div className="flex-1">
                                                                    {key || 'N/A'}
                                                                </div>
                                                                <div className="flex-1" onClick={() => {
                                                                    console.log(key2)
                                                                }}>
                                                                    ${Number(value)} {`${index === 0 ? "/hr" : "/day"}`}
                                                                </div>
                                                                <div className="flex-1">
                                                                    $ {Number(valuetwo)} /week
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <p>No travel per diems available.</p>
                                            )
                                        }

                                    </div>

                                </div>


                                <div className="d-flex shift-details">
                                    <div className="flex-2">
                                        <h3>Cancel Policy</h3>
                                        <p
                                            dangerouslySetInnerHTML={{__html: basicDetails?.requirement?.contract_requirements?.cancel_policy?.replace(/\n/g, '<br/>')}}/>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-2">
                                        <h3>Staff Requirements</h3>
                                        <p
                                            dangerouslySetInnerHTML={{__html: basicDetails?.requirement?.contract_requirements?.staff_requirements?.replace(/\n/g, '<br/>')}}/>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-2">
                                        <h3>Job Details</h3>
                                        <p
                                            dangerouslySetInnerHTML={{__html: basicDetails?.requirement?.contract_requirements?.job_details?.replace(/\n/g, '<br/>')}}/>
                                    </div>
                                </div>
                                <div className="d-flex shift-details">
                                    <div className="flex-2">
                                        <h3>Job Benefits</h3>
                                        <p
                                            dangerouslySetInnerHTML={{__html: basicDetails?.requirement?.contract_requirements?.job_benefits?.replace(/\n/g, '<br/>')}}/>
                                    </div>
                                </div>
                            </>}


                    </div>
                </div>
            )}

            {
                basicDetails?.status === "rejected" && <div className="mrg-top-10 custom-border pdd-top-10">
                    <div className="">
                        <h2>Reason for Rejection</h2>
                        <p>{basicDetails?.rejected_reason}</p>
                    </div>
                    <div className="rejection-wrapper d-flex">
                        <div>
                            <h3 className="rejection-header">Rejected By</h3>
                            <p>
                                {basicDetails?.rejected_by?.first_name} &nbsp;{" "}
                                {basicDetails?.rejected_by?.last_name}
                            </p>
                        </div>
                        <div className="mrg-left-50">
                            <h3 className="rejection-header">Role</h3>
                            <p>{basicDetails?.rejected_by?.role}</p>
                        </div>
                        <div className="mrg-left-50">
                            <h3 className="rejection-header">Rejected On</h3>
                            <p>
                                {basicDetails?.rejected_by?.date
                                    ? moment(basicDetails?.rejected_by?.date).format("MM-DD-YYYY")
                                    : "N/A"}
                            </p>
                        </div>
                    </div>
                </div>
            }


        </div>
    );
};

export default PendingShiftsViewComponent;
