import {ApprovedContractAddShiftsValidation} from "../../AddShiftsValidation";
import {Field, FieldArray, Form, Formik, FormikHelpers} from "formik";
import {TextField as FormikTextFiled} from "formik-material-ui";
import TextField from "@material-ui/core/TextField";
import {Button, InputAdornment, MenuItem, Paper} from "@material-ui/core";
import {ENV, ImageConfig} from "../../../../../constants";
import React, {useCallback, useEffect, useState} from "react";
import {ApiService, CommonService} from "../../../../../helpers";
import moment from "moment/moment";
import {useHistory} from "react-router-dom";
import "./ApprovedContractTravelComponent.scss"
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePickers from "react-multi-date-picker";
import {contactTypeList} from "../../../../../constants/data";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface ContractProps {
    facilityId?: any
}

interface PerDiem {
    type: string;
    rate: any;
    week_rate: any;
}

interface ContactShiftItem {
    temp_id?: string;
    title: string;
    hcp_type: string;
    mode: string;
    start_time: string | number;
    end_time: string | number;
    start_date: any;
    end_date: any;
    shift_type: string;
    warning_type: string;
    hcp_count: string;
    hcp_speciality: string;
    hazard_rate: string;
    shift_details: string;
    travel_per_diems: PerDiem[];
    day_per_week: string;
    contract_requirements: {
        cancel_policy: string,
        staff_requirements: string,
        job_details: string,
        job_benefits: string,
    }
}

let contractShiftInitialState: ContactShiftItem = {
    day_per_week: "",
    temp_id: "",
    title: "",
    hcp_type: "",
    mode: "",
    start_time: "",
    end_time: "",
    shift_type: "",
    warning_type: "",
    hcp_count: "",
    hcp_speciality: "",
    end_date: null,
    start_date: null,
    hazard_rate: "",
    shift_details: "",
    contract_requirements: {
        cancel_policy: "",
        staff_requirements: "",
        job_benefits: ""
        ,
        job_details: "",
    },
    travel_per_diems: [
        {
            type: 'Shift Rate',
            rate: "",
            week_rate: "",
        },
        {
            type: 'Housing',
            rate: "",
            week_rate: "",
        },
        {
            type: 'Meals',
            rate: "",
            week_rate: "",
        },
    ],
};
const showDropDownBelowField: any = {
    MenuProps: {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        getContentAnchorEl: null,
    },
};

const ApprovedContractTravelShiftCreation = (props: ContractProps) => {
    const {facilityId} = props;
    const [approvedHCPs, setApprovedHCPs] = useState([]);
    const [value, setValue] = useState<any>(null);
    const history = useHistory();
    const [requestedBy, setRequestedBy] = useState<any>("");


    const onContractAdd = useCallback((data: any, {setSubmitting, resetForm}: FormikHelpers<any>) => {
        console.log(data)
        if (!facilityId) {
            CommonService.showToast("Please select Facility");
            setSubmitting(false);
            return;
        }


        let {travel_per_diem_rate, travel_per_diems, ...restData} = data;

        let payload: any = {
            ...restData,
            contract_requirements: {
                ...data.contract_requirements,
                travel_per_diems: data?.travel_per_diems?.map((perDiem: any) => ({
                    [perDiem.type]: perDiem.rate,
                    week_rate: perDiem.week_rate,
                }))
            }
        };
        let shift_dates = value?.map((item: any) => {
            let mm = item?.month?.number;
            let dd = item?.day;
            let yyyy = item?.year;

            let shift_date = moment(`${yyyy}-${mm}-${dd}`).format("YYYY-MM-DD");
            return shift_date;
        });

        payload.type = 'contract'
        payload.shift_rate = travel_per_diems[0]?.rate;
        payload.facility_id = facilityId;
        payload.requested_by = 'facility';
        payload.shift_dates = shift_dates

        console.log(payload);
        ApiService.upload(ENV.API_URL + "requirement/contract/preApprovedShifts", payload)
            .then((res) => {
                resetForm();
                CommonService.showToast(res.message || "Success", "success");
                history.push("/approvedShifts/list");
            })
            .catch((err) => {
                setSubmitting(false);
                if (typeof err?.error === 'string') {
                    CommonService.showToast(err?.error || "Error", "error")
                } else {
                    const formattedError = Object.entries(err?.error);
                    formattedError.map((err: any) => {
                        let hcpName = err[0];
                        let hcpDate = moment(err[1]).format("MM-DD-YYYY");
                        return CommonService.showToast(`${hcpName} already approved on ${hcpDate}` || "Error", "error");
                    });
                }
            });

    }, [facilityId, history, value]);

    function handleDatePicker(value: any) {
        setValue(value);
    }

    const getAllApprovedHcps = useCallback(() => {
        let payload: any = {
            is_approved: true,
        };
        CommonService._api
            .post(ENV.API_URL + "hcp/lite", payload)
            .then((resp) => {
                const approvedHcps = resp?.data?.map((item: any) => ({
                    label: `${item?.first_name} ${item?.last_name}`,
                    value: item?._id,
                }));
                setApprovedHCPs(approvedHcps || []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        getAllApprovedHcps()
    }, [getAllApprovedHcps]);

    return (
        <>
            <div className={'contract-travel-approved-shift-component'}>
                <Formik
                    onSubmit={onContractAdd} initialValues={contractShiftInitialState}
                    validationSchema={ApprovedContractAddShiftsValidation}>
                    {({isSubmitting, isValid, resetForm, handleChange, setFieldValue, values}) => (
                        <Form className={"form-holder"} id="shift-add-form">
                            <div className="shift-open-container">
                                <div style={{width: '100%'}} className={"search-input-box"}>
                                    <Autocomplete

                                        disableClearable
                                        PaperComponent={({children}) => <Paper
                                            style={{color: "#1e1e1e"}}>{children}</Paper>}
                                        value={requestedBy}
                                        options={approvedHCPs}
                                        getOptionLabel={(option: any) => option.label}
                                        placeholder={"Time Sheet"}
                                        id="input_select_status"
                                        // className="mrg-top-10"
                                        onChange={($event, value) => {
                                            setRequestedBy(value);
                                            console.log(value?.value);
                                            setFieldValue("hcp_id", value?.value)
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} id="select_requested_by" variant="outlined"
                                                       placeholder={"Staff"}/>
                                        )}
                                        disableCloseOnSelect
                                    />
                                </div>


                                <div className="d-flex shift-third-row shift-row mrg-top-30 ">
                                    {/*<div className="shift-calender">*/}
                                    {/*    <Field*/}
                                    {/*        SelectProps={showDropDownBelowField}*/}
                                    {/*        id="input_shift_requirement_shift_timings"*/}
                                    {/*        variant="outlined"*/}
                                    {/*        disbled={false}*/}
                                    {/*        select*/}
                                    {/*        required*/}
                                    {/*        name="hcp_id"*/}
                                    {/*        component={TextField}*/}
                                    {/*        label="Staff"*/}
                                    {/*        fullWidth*/}
                                    {/*        onChange={(e: any) => {*/}
                                    {/*            setFieldValue("hcp_id", e.target.value);*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        /!*<MenuItem value="">Select Shift Timing</MenuItem>*!/*/}
                                    {/*        {approvedHCPs.length > 0 &&*/}
                                    {/*            approvedHCPs?.map((item: any, index: any) => {*/}
                                    {/*                return (*/}
                                    {/*                    <MenuItem value={item?.code} key={index}>*/}
                                    {/*                        {item?.name}*/}
                                    {/*                    </MenuItem>*/}
                                    {/*                );*/}
                                    {/*            })}*/}
                                    {/*    </Field>*/}
                                    {/*</div>*/}
                                    <div className="shift-calender">
                                        <Field
                                            required
                                            inputClass="custom-input"
                                            className="rmdp-mobile"
                                            plugins={[<DatePanel eachDaysInRange/>]}
                                            format="MM/DD/YYYY"
                                            // range={mode === "range" ? true : false}
                                            multiple={true}
                                            onChange={handleDatePicker}
                                            value={value}
                                            variant="inline"
                                            inputVariant="outlined"
                                            placeholder={"Date(s)*"}
                                            id="input_shift_requirement_shift_datepicker"
                                            name="shift_dates"
                                            InputLabelProps={{shrink: true,}}
                                            component={DatePickers}
                                        />
                                    </div>
                                    <div className="shift-calender">
                                        <Field disabled={false} fullWidth variant="outlined" type="time"
                                               component={FormikTextFiled}
                                               required
                                               name="shift_start_time" label="Start Time"
                                               InputLabelProps={{shrink: true}}
                                               id="input_shift_add_shift_start_time"
                                               onChange={(e: any) => {
                                                   setFieldValue("start_time", CommonService.convertHoursToMinutes(e.target.value));
                                               }}
                                        />
                                    </div>

                                    <div className="shift-calender">
                                        <Field disabled={false} fullWidth variant="outlined" type="time"
                                               component={FormikTextFiled}
                                               required
                                               name="shift_start_time" label="End Time"
                                               InputLabelProps={{shrink: true}}
                                               id="input_shift_add_shift_start_time"
                                               onChange={(e: any) => {
                                                   setFieldValue("end_time", CommonService.convertHoursToMinutes(e.target.value));
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className="shift-open-container">
                                    <div className="d-flex shift-third-row shift-row mrg-top-30 ">
                                        <div className="shift-calender">
                                            <Field
                                                SelectProps={showDropDownBelowField}
                                                id="input_shift_requirement_shift_type"
                                                variant="outlined"
                                                disabled={false}
                                                select
                                                required
                                                name="shift_type"
                                                component={FormikTextFiled}
                                                onChange={(e: any) => {
                                                    const shiftType = e.target.value;
                                                    console.log(e.target.value);
                                                    setFieldValue("shift_type", shiftType);
                                                }}
                                                label="Shift Type"
                                                fullWidth
                                            >
                                                <MenuItem value="">Select Shift Type</MenuItem>
                                                {contactTypeList &&
                                                    contactTypeList.map((item: any, index: any) => (
                                                        <MenuItem value={item.code} key={index}>
                                                            {item?.title}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </div>
                                        <div className="shift-calender">
                                        </div>
                                        <div className="shift-calender">
                                        </div>
                                        <div className="shift-calender">
                                        </div>
                                    </div>
                                </div>


                                <div className={'form-header-text mrg-top-30'}>
                                    Rates
                                </div>

                                {/*patient details wrapper*/}
                                <div className={"mrg-top-20"}>
                                    <div>
                                        <FieldArray name="travel_per_diems">
                                            {({push, remove}) => (
                                                <div className="card">
                                                    <div>
                                                        {values?.travel_per_diems?.map((perDiem, index) => (
                                                            <div className="shift-row mrg-top-20">
                                                                <Field
                                                                    variant="outlined"
                                                                    name={`travel_per_diems.${index}.type`}
                                                                    type={"text"}
                                                                    disabled={index < 3}
                                                                    component={FormikTextFiled}
                                                                    placeholder={'Enter value'}
                                                                    //  label="Travel Per Diems*"
                                                                    fullWidth autoComplete="off"
                                                                    id="patients_details_open_positions"
                                                                />
                                                                {index === 0 &&
                                                                    <Field disabled={false}
                                                                           variant="outlined"
                                                                           name={`travel_per_diems.${index}.rate`}
                                                                           type={"number"}
                                                                           component={FormikTextFiled}
                                                                        //  placeholder={""}
                                                                           label="$/hr"
                                                                           fullWidth
                                                                           autoComplete="off"
                                                                           id="patients_details_open_positions"
                                                                           InputProps={{
                                                                               startAdornment:
                                                                                   <InputAdornment
                                                                                       position="start">$</InputAdornment>,
                                                                           }}
                                                                        // onChange={(e: any) => {
                                                                        //     setFieldValue(`travel_per_diems.${index}.total`, e)
                                                                        // }}
                                                                    />}
                                                                {index > 0 &&
                                                                    <Field disabled={false}
                                                                           variant="outlined"
                                                                           name={`travel_per_diems.${index}.rate`}
                                                                           type={"number"}
                                                                           component={FormikTextFiled}
                                                                        //  placeholder={"$/day"}
                                                                           label="$/day"
                                                                           fullWidth
                                                                           autoComplete="off"
                                                                           id="patients_details_open_positions"
                                                                           InputProps={{
                                                                               startAdornment:
                                                                                   <InputAdornment
                                                                                       position="start">$</InputAdornment>,
                                                                           }}
                                                                        // onChange={(e: any) => {
                                                                        //     setFieldValue(`travel_per_diems.${index}.total`, e)
                                                                        // }}
                                                                    />
                                                                }
                                                                <div className={'d-flex'}
                                                                     style={{minWidth: "31.5%"}}>
                                                                    <Field disabled={false}
                                                                           variant="outlined"
                                                                           name={`travel_per_diems.${index}.week_rate`}
                                                                           type={"number"}
                                                                           component={FormikTextFiled}
                                                                           label="$/week"
                                                                           fullWidth
                                                                           autoComplete="off"
                                                                        //  placeholder={'$/week'}
                                                                           id="patients_details_open_positions"
                                                                           InputProps={{
                                                                               startAdornment:
                                                                                   <InputAdornment
                                                                                       position="start">$</InputAdornment>,
                                                                           }}
                                                                        // value={131}
                                                                        //@ts-ignore
                                                                        //  value={`${Number(values?.travel_per_diems[index]?.rate) * Number(values?.contract_requirements?.days_per_week) || 0}`}
                                                                    />
                                                                    {(values?.travel_per_diems?.length > 3 && index > 2) &&
                                                                        <div
                                                                            className="ts-col-1 ts-justify-content-center ts-align-content-center mrg-top-10 mrg-left-10">
                                                                            <div onClick={() => {
                                                                                remove(index)
                                                                                // console.log(values)
                                                                            }}
                                                                                 className={'cursor-pointer'}>
                                                                                <img
                                                                                    src={ImageConfig.ContractDelete}
                                                                                    alt={ImageConfig.ContractDelete}/>
                                                                            </div>
                                                                        </div>}
                                                                </div>

                                                            </div>
                                                        ))}
                                                        <div className={'mrg-bottom-10 mrg-top-20'}>
                                                            <div
                                                                className={'d-flex justify-content-space-between'}>
                                                                <div
                                                                    className={'d-flex ts-justify-content-center align-items-center'}>
                                                                    <Button
                                                                        variant={"outlined"}
                                                                        color={"primary"}

                                                                        onClick={() =>
                                                                            push({
                                                                                type: '',
                                                                                rate: "",
                                                                                total: ""
                                                                            })
                                                                        }
                                                                    >
                                                                        Add More
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </FieldArray>


                                    </div>
                                </div>

                                {/*other-details*/}

                                {/*<div className="shift-rate-container">*/}
                                {/*    <div className="shift-text-container">*/}
                                {/*        <FormLabel className="shift-text">Shift Rate</FormLabel>*/}
                                {/*        <p className="shift-sub-text">*/}
                                {/*            Please Select Yes,if you want the Shift Rate to Reflect in*/}
                                {/*            Mobile*/}
                                {/*            Application.*/}
                                {/*            <br/>*/}
                                {/*            Please Select No,if you do not want the Shift Rate to*/}
                                {/*            Reflect in*/}
                                {/*            Mobile Application*/}
                                {/*        </p>*/}
                                {/*    </div>*/}
                                {/*    <div className="shift-rate-actions">*/}
                                {/*        <NormalRadioGroup name="shift_rate" value={isShiftRate}*/}
                                {/*                          onChange={handleShiftRate}>*/}
                                {/*            <div className="d-flex">*/}
                                {/*                {genericAcknowledgement &&*/}
                                {/*                    genericAcknowledgement.map((item: any, index) => {*/}
                                {/*                        return (*/}
                                {/*                            <div>*/}
                                {/*                                <FormControlLabel*/}
                                {/*                                    key={"input_add_shift_rate" + index}*/}
                                {/*                                    value={item.value}*/}
                                {/*                                    control={<Radio required*/}
                                {/*                                                    disabled={*/}
                                {/*                                                        // !facilityData?.show_shift_rate ||*/}
                                {/*                                                        isSubmitting}/>}*/}
                                {/*                                    disabled={false}*/}
                                {/*                                    name="shift_rate"*/}
                                {/*                                    label={item.label}*/}
                                {/*                                />*/}
                                {/*                            </div>*/}
                                {/*                        );*/}
                                {/*                    })}*/}
                                {/*            </div>*/}
                                {/*        </NormalRadioGroup>*/}
                                {/*        <NormalTextField*/}
                                {/*            fullWidth*/}
                                {/*            inputProps={{maxLength: 5}}*/}
                                {/*            onChange={handleShiftRateValue}*/}
                                {/*            value={shiftRateValue}*/}
                                {/*            id="input_shift_rate"*/}
                                {/*            required={isShiftRate !== "false"}*/}
                                {/*            variant="outlined"*/}
                                {/*            placeholder={"Shift Rate"}*/}
                                {/*            disabled={isShiftRate === "false"}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}


                                <div className={'form-header-text mrg-top-30'}>
                                    Cancel Policy*
                                </div>
                                <div className="shift-third-row  mrg-top-20">
                                    <Field
                                        id="input_shift_requirement_shift_details"
                                        //label="Enter cancel policy"
                                        placeholder="Enter cancel policy"
                                        variant="outlined"
                                        component={FormikTextFiled}
                                        type={"text"}
                                        name="contract_requirements.cancel_policy"
                                        disabled={false}
                                        fullWidth
                                        required
                                        multiline
                                        minRows={4}
                                        maxRows={4}
                                    />
                                </div>

                                <div className={'form-header-text mrg-top-30'}>
                                    Staff Requirement
                                </div>

                                <div className="shift-third-row  mrg-top-20">
                                    <Field
                                        id="input_shift_requirement_shift_details"
                                        //  label="Shift Requirement Details"
                                        placeholder="References, Skills, certifications, or required years of experience"
                                        variant="outlined"
                                        component={FormikTextFiled}
                                        type={"text"}
                                        name="contract_requirements.staff_requirements"
                                        disabled={false}
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        maxRows={4}
                                    />
                                </div>

                                <div className={'form-header-text mrg-top-30'}>
                                    Job Details
                                </div>

                                <div className="shift-third-row  mrg-top-30">
                                    <Field
                                        id="input_shift_requirement_shift_details"
                                        //  label="Shift Requirement Details"
                                        placeholder="Enter any relevant details about the job"
                                        variant="outlined"
                                        component={FormikTextFiled}
                                        type={"text"}
                                        name="contract_requirements.job_details"
                                        disabled={false}
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        maxRows={4}
                                    />
                                </div>

                                <div className={'form-header-text mrg-top-30'}>
                                    Job Benefits
                                </div>

                                <div className="shift-third-row  mrg-top-30">
                                    <Field
                                        id="input_shift_requirement_shift_details"
                                        // label="Shift Requirement Details"
                                        placeholder={'• Allied travel assignments are typically for 13 weeks and offer ​ generous packages that include: \n' +
                                            '• Competitive pay rates \n' +
                                            '• Medical, Dental, Vision \n' +
                                            '• 401(k) and Flex Spending \n' +
                                            '• Life Insurance \n' +
                                            '• Accident and Short-term Disability Coverage \n' +
                                            '• Free Continuing Education \n' +
                                            '• Competitive Housing Deal \n' +
                                            '• Refer a friend and earn extra cash!'}
                                        variant="outlined"
                                        component={TextField}
                                        type={"text"}
                                        name="contract_requirements.job_benefits"
                                        disabled={false}
                                        fullWidth
                                        multiline
                                        minRows={9}
                                        maxRows={9}
                                    />
                                </div>


                            </div>

                            <div className="add-shift-btn-grp mrg-top-30">
                                <Button
                                    //  disabled={timesheetFile?.length===0}
                                    type="submit"
                                    id="btn_add_shift_requirement_save"
                                    variant={"contained"}
                                    className={"normal"}
                                    color={"primary"}
                                >
                                    Create Approved Shift
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}
export default ApprovedContractTravelShiftCreation
