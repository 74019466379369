import React, {useCallback, useEffect, useState} from "react";
import {Button, FormControlLabel, IconButton, MenuItem, Radio} from "@material-ui/core";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {TextField, RadioGroup} from "formik-material-ui";
import FormLabel from "@material-ui/core/FormLabel";
import "./EmployeeEditScreen.scss";
import PhoneInputComponent from "../../../components/phoneInput/PhoneInputComponent";
import {ApiService, CommonService, Communications} from "../../../helpers";
import {ENV} from "../../../constants";
import {genderTypes, roles} from "../../../constants/data";
import * as Yup from "yup";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {useHistory, useParams} from "react-router-dom";
import LoaderComponent from "../../../components/LoaderComponent";
import ScrollToTop from "react-scroll-to-top";
import DialogComponent from "../../../components/DialogComponent";
import LeavePageConfirmationComponent from "../../../components/shared/LeavePageConfirmationComponent";
import {useSelector} from "react-redux";
import {StateParams} from "../../../store/reducers";


const showDropDownBelowField = {
    MenuProps: {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        getContentAnchorEl: null,
    },
};

interface EmployeeAddProps {
    first_name?: string;
    last_name: string;
    email: string;
    contact_number: string;
    address?: {
        street: string;
        city: string;
        state: string;
        region: string;
        country: string;
        zip_code: string;
    };
    role: string;
    gender: string;
    password: string;
    confirm_password: string;
}

const EmployeeEditScreen = () => {
    const param = useParams<any>();
    const {id} = param;
    const history = useHistory();
    // const [regions, setRegions] = useState<any>([]);
    // const [regIsLoading, setRegIsLoading] = useState<boolean>(true);
    const {regions, isLoading} = useSelector((state: StateParams) => state.meta)
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    //const [basicDetails, setBasicDetails] = useState<any>(null);
    const {userBasicDetails, isUserBasicDetailsLoading} = useSelector((state: StateParams) => state.employeeManagement)
    //const [isEmployeeDetailsLoading, setIsEmployeeLoading] = useState<boolean>(false);
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);

    const [isPasswordChange, setIsPasswordChange] = useState<boolean>(false)

    function createSchemaForPassword(isPasswordChange: boolean) {

        const regularSchema = Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match")
        const forConfirmPassword = Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required('Required')
        return isPasswordChange ? forConfirmPassword : regularSchema
    }

    const employeeFormValidation = Yup.object({
        first_name: Yup.string()
            .typeError("must be a text")
            .min(3, "min 3 letters")
            .trim("empty space not allowed")
            .required("Required")
            .max(50, "max limit 50")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        last_name: Yup.string()
            .typeError("must be a text")
            .min(3, "min 3 letters")
            .trim("empty space not allowed")
            .required("Required")
            .max(50, "max limit 50")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        email: Yup.string().min(3, "invalid").trim("empty space not allowed").typeError("must be a text").email("invalid").required("Required").max(50, "max limit 50"),
        contact_number: Yup.string().min(12, "min 10 digits").required("Required"),
        gender: Yup.string().typeError("must be a text").min(2, "invalid").trim("empty space not allowed"),
        address: Yup.object({
            street: Yup.string().typeError("must be a text").min(3, "min 3 letters").trim("empty space not allowed").max(150, "max limit 150"),
            city: Yup.string().typeError("must be a text").min(2, "min 2 letters").trim("empty space not allowed").max(150, "max limit 150"),
            state: Yup.string().typeError("must be a text").min(2, "min 2 letters").trim("empty space not allowed").max(150, "max limit 150"),
            region: Yup.string().typeError("must be a text").min(2, "min 2 letters").trim("empty space not allowed").max(150, "max limit 150"),
            country: Yup.string().typeError("must be a text").min(2, "min 2 letters").trim("empty space not allowed").max(150, "max limit 150"),
            zip_code: Yup.string().typeError("must be a text").trim("empty space not allowed").min(5, "min 5 digits").max(6, "max 6 digits allowed"),
        }),
        role: Yup.string().required("Required"),
        password: Yup.string().typeError("must be a text").min(6, "min 6 letters").max(16, "max limit 16"),
        confirm_password: createSchemaForPassword(isPasswordChange)
    });


    // const getRegions = useCallback(() => {
    //   CommonService._api
    //     .get(ENV.API_URL + "meta/hcp-regions")
    //     .then((resp) => {
    //       setRegions(resp.data || []);
    //       setRegIsLoading(false);
    //     })
    //     .catch((err) => {
    //       //console.log(err);
    //     });
    // }, []);

    // const getEmployeeDetails = useCallback(() => {
    //   setIsEmployeeLoading(true);
    //   CommonService._api
    //     .get(ENV.API_URL + "user/" + id)
    //     .then((resp) => {
    //       setBasicDetails(resp?.data);
    //       setIsEmployeeLoading(false);
    //     })
    //     .catch((err) => {
    //      // console.log(err);
    //       setIsEmployeeLoading(false);
    //     });
    // }, [id]);

    const formatRole = (role: string): string => {
        let formattedRole: any = roles.find((item) => item.label === role);
        return formattedRole?.value;
    };

    let EmployeeInitialState: EmployeeAddProps = {
        first_name: userBasicDetails?.first_name,
        last_name: userBasicDetails?.last_name,
        email: userBasicDetails?.email,
        contact_number: userBasicDetails?.contact_number?.toString(),
        address: {
            street: userBasicDetails?.address?.street,
            city: userBasicDetails?.address?.city,
            state: userBasicDetails?.address?.state,
            region: userBasicDetails?.address?.region,
            country: userBasicDetails?.address?.country,
            zip_code: userBasicDetails?.address?.zip_code,
        },
        role: formatRole(userBasicDetails?.role),
        gender: userBasicDetails?.gender,
        password: "",
        confirm_password: "",
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const openAdd = useCallback(() => {
        setIsAddOpen(true);
    }, []);

    const cancelAdd = useCallback(() => {
        setIsAddOpen(false);
    }, []);

    const confirmAdd = useCallback(() => {
        history.push(`/employee/view/${id}`);
    }, [history, id]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Edit Employee");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    const onAdd = (employee: EmployeeAddProps, {
        setSubmitting,
        setErrors,
        resetForm,
        setFieldValue
    }: FormikHelpers<any>) => {
        employee.email = employee.email.toLowerCase();
        ApiService.put(ENV.API_URL + "user/" + id, employee)
            .then((resp: any) => {
                CommonService.showToast(resp?.msg || "Success", "success");
                setSubmitting(false);
                history.push("/employee/view/" + id);
            })
            .catch((err) => {
                CommonService.handleErrors(setErrors, err);
                setSubmitting(false);
            });
    };

    if (isLoading || isUserBasicDetailsLoading) {
        return <LoaderComponent/>;
    }

    return (
        !isLoading &&
        !isUserBasicDetailsLoading && (
            <div className="employee-add screen ccrud-layout pdd-40">
                <DialogComponent open={isAddOpen} cancel={cancelAdd}>
                    <LeavePageConfirmationComponent cancel={cancelAdd} confirm={confirmAdd} confirmationText={""}
                                                    notext={"Cancel"} yestext={"Leave"}/>
                </DialogComponent>
                <Formik initialValues={EmployeeInitialState} validateOnChange={true}
                        validationSchema={employeeFormValidation} onSubmit={onAdd}>
                    {({isSubmitting, setFieldValue, isValid, resetForm}) => (
                        <Form id="employee-add-form" className={"form-holder"}>
                            <div>
                                <div className="custom-border pdd-40">
                                    <h3 className="card-header">Basic Details</h3>
                                    <div className="input-container">
                                        <Field variant="outlined" name="first_name" type={"text"} component={TextField}
                                               id="input_employee_add_first_name" label="First Name*" fullWidth
                                               autoComplete="off"/>
                                        <Field variant="outlined" name="last_name" id="input_employee_add_last_name"
                                               type={"text"} component={TextField} label="Last Name*" fullWidth
                                               autoComplete="off"/>
                                    </div>
                                    <div className="input-container">
                                        <Field variant="outlined" component={TextField} type={"email"} fullWidth
                                               autoComplete="off" className="flex-1" label="Email*" name="email"
                                               id="input_employee_add_email"/>
                                        <div className="flex-1">
                                            <Field required name={"contact_number"} className="flex-1">
                                                {(field: FieldProps) => {
                                                    return <PhoneInputComponent field={field}
                                                                                placeholder={"Enter Phone number*"}/>;
                                                }}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="input-container">
                                        <Field variant="outlined" name="address.street" type={"text"}
                                               component={TextField} label="Street" id="input_employee_add_street"
                                               fullWidth autoComplete="off"/>
                                        <Field variant="outlined" name="address.city" type={"text"}
                                               component={TextField} id="input_employee_add_city" label="City" fullWidth
                                               autoComplete="off"/>
                                    </div>
                                    <div className="input-container">
                                        <Field SelectProps={showDropDownBelowField} variant="outlined"
                                               component={TextField} type={"text"} select label="Region"
                                               name="address.region" id="menu_employee_add_region" fullWidth
                                               autoComplete="off">
                                            <MenuItem value="">Select Region</MenuItem>
                                            {regions &&
                                                regions.map((item: any, index: any) => (
                                                    <MenuItem value={item.code} key={"region_" + index}
                                                              id={"menu_employee_add_region" + item.code}>
                                                        {item.title}
                                                    </MenuItem>
                                                ))}
                                        </Field>
                                        <Field variant="outlined" name="address.state" id="input_employee_add_state"
                                               type={"text"} component={TextField} label="State" fullWidth
                                               autoComplete="off"/>
                                    </div>
                                    <div className="input-container">
                                        <Field variant="outlined" fullWidth name="address.zip_code" type={"number"}
                                               component={TextField} id="input_employee_add_zip" label="Zip"
                                               autoComplete="off"/>
                                        <Field variant="outlined" name="address.country" type={"text"}
                                               component={TextField} id="input_employee_add_country" label="Country"
                                               fullWidth autoComplete="off"/>
                                    </div>
                                    <div className="input-container">
                                        <div className="flex-1">
                                            <div className="pdd-top-10">
                                                <FormLabel className={"form-label"}>{"Gender*"}</FormLabel>
                                            </div>
                                            <div className="mrg-top-10">
                                                <Field component={RadioGroup} name="gender" id="radio_add_gender">
                                                    <div className="d-flex">
                                                        {genderTypes.map((item: any, index) => {
                                                            return (
                                                                <div>
                                                                    <FormControlLabel key={"gender_type_" + index}
                                                                                      value={item.value}
                                                                                      control={<Radio required
                                                                                                      disabled={isSubmitting}/>}
                                                                                      disabled={isSubmitting}
                                                                                      label={item.label}/>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="flex-1">
                                            <Field SelectProps={showDropDownBelowField}
                                                   disabled={userBasicDetails?.role === "Admin"} variant="outlined"
                                                   name="role" type={"text"} component={TextField} select label="Role*"
                                                   fullWidth autoComplete="off" id="employee_add_role">
                                                <MenuItem value="">Select Role</MenuItem>
                                                {roles &&
                                                    roles.map((item: any, index: any) => (
                                                        <MenuItem value={item.value} key={"role_" + index}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="input-container">
                                        <div className="form-field position-relative flex-1">
                                            <Field
                                                name="password"
                                                onChange={(e: any) => {
                                                    setFieldValue('password', e.target.value)
                                                    if (e.target.value.length > 0) {
                                                        setIsPasswordChange(true)
                                                    } else {
                                                        setIsPasswordChange(false)
                                                    }

                                                }}
                                                type={showNewPassword ? "text" : "password"}
                                                component={TextField}
                                                variant={"outlined"}
                                                color={"primary"}
                                                autoComplete="off"
                                                label="Password*"
                                                id="input_new_password"
                                                placeholder={"Enter New Password"}
                                                inputProps={{maxLength: 16}}
                                            />
                                            <div className={"eye_btn_wrapper"}>
                                                <IconButton size={"small"} aria-label="toggle password visibility"
                                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                                            onMouseDown={handleMouseDownPassword}
                                                            id="btn_new_password_show">
                                                    {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </div>
                                        </div>
                                        <div className="form-field position-relative flex-1">
                                            <Field
                                                name="confirm_password"
                                                type={showConfirmPassword ? "text" : "password"}
                                                component={TextField}
                                                variant={"outlined"}
                                                color={"primary"}
                                                autoComplete="off"
                                                id="input_confirm_password"
                                                label="Confirm Password*"
                                                placeholder={"Confirm Password"}
                                                inputProps={{maxLength: 16}}
                                            />
                                            <div className={"eye_btn_wrapper"}>
                                                <IconButton size={"small"} aria-label="toggle password visibility"
                                                            id="btn_confirm_password_show"
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            onMouseDown={handleMouseDownPassword}>
                                                    {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mrg-top-20 text-align">
                                    <Button onClick={openAdd} variant={"outlined"} id="cancel-employee-btn"
                                            size={"medium"} color={"primary"}>
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={isSubmitting}
                                        variant={"contained"}
                                        id="Add-employee-btn"
                                        type={"submit"}
                                        size={"medium"}
                                        color={"primary"}
                                        className={isSubmitting ? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30" : "mrg-left-20 pdd-left-30 pdd-right-30"}
                                    >
                                        {isSubmitting ? "Saving" : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <ScrollToTop smooth color="white"/>
            </div>
        )
    );
};

export default EmployeeEditScreen;
