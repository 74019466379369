import { Button, FormControlLabel, Radio } from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import React from "react";
import * as Yup from "yup";
import { ENV } from "../../../constants";
import { warningZone } from "../../../constants/data";
import { ApiService, CommonService } from "../../../helpers";

import "./EditShiftDetailsDialog.scss";

interface EditShiftDetailsDialogProps {
	cancel: () => void;
	confirm: () => void;
	shiftId?: string;
	shiftDetails:any;
}

const EditShiftDetailsDialog = (props: EditShiftDetailsDialogProps) => {
	const { confirm, cancel, shiftId,shiftDetails } = props;

	const shiftRateInitialState = {

		hcp_differential: shiftDetails?.payments?.differential ?? "",
		hazard_rate: shiftDetails?.hazard_rate ?? "",
		warning_type: shiftDetails?.warning_type ?? "",
	};

	const onlyNums = /^\d{1,3}(\.$|\.\d{1,3}$|$)/;
	const validation = Yup.object({
		hcp_differential: Yup.number().typeError('must be a number'),
		hazard_rate: Yup.string(),
		warning_type: Yup.string(),
	});

	const onAdd = (data: any, { setSubmitting, setErrors, resetForm, setFieldValue }: FormikHelpers<any>) => {
		const { hcp_differential, hazard_rate, warning_type } = data;

		let payload: any = {
			hcp_differential,
			hazard_rate,
			warning_type,
		};

		ApiService.put(ENV.API_URL + "shift/" + shiftId, payload)
			.then((res: any) => {
				CommonService.showToast(res?.msg || "Success", "success");
				setSubmitting(false);
				confirm();
			})
			.catch((err) => {
				CommonService.showToast(err?.msg || err?.error || "Error", "error");
				setSubmitting(false);
			});
	};
	return (
		<div className="pdd-40 show-shift-rate-screen">
			<p className="main-heading">Shift Details</p>
			<div className="mrg-top-10 ">
				<Formik
					initialValues={shiftRateInitialState}
					validateOnChange={true}
					validationSchema={validation}
					onSubmit={onAdd}
				>
					{({ isSubmitting, handleSubmit, isValid, values, resetForm, setFieldValue }) => (
						<Form id="show-shift-rate-form" className={"shift-rate-form form-holder"}>

							<div className="shift-rate-container">
								<div className="shift-rate-text">
									<p className="sub-heading"> Staff Differential Rate</p>
								</div>
								<Field
									name="hcp_differential"
									//inputProps={{ maxLength: 5 }}
									value={values.hcp_differential}
									component={TextField}
									variant={"outlined"}
									color={"primary"}
									onChange={(e: any) => {
									//	if (e.target.value === "" || onlyNums.test(e.target.value)) {
											setFieldValue("hcp_differential", e.target.value);
									//	}
									}}
									id="hcp_differential"
									placeholder={"Enter Value"}
								/>
							</div>
							<div className="shift-rate-container">
								<div className="shift-rate-text">
									<p className="sub-heading"> Hazard Rate</p>
								</div>

								<Field
									name="hazard_rate"
									//inputProps={{ maxLength: 5 }}
									value={values.hazard_rate}
									component={TextField}
									variant={"outlined"}
									color={"primary"}
									onChange={(e: any) => {
										if (e.target.value === "" || onlyNums.test(e.target.value)) {
											setFieldValue("hazard_rate", e.target.value);
										}
									}}
									id="hazard_rate"
									placeholder={"Enter Value"}
								/>
							</div>
							<div className="shift-rate-container">
								<div className="shift-rate-text">
									<p className="sub-heading"> Warning Zone</p>
								</div>
								<div className="mrg-top-10">
									<Field required component={RadioGroup} name="warning_type">
										<div className="d-flex">
											{warningZone &&
												warningZone.map((item: any, index) => {
													return (
														<div>
															<FormControlLabel
																key={"input_add_shift_warning_type" + index}
																value={item.value}
																control={<Radio required disabled={isSubmitting} />}
																disabled={isSubmitting}
																name="warning_type"
																label={item.label}
															/>
														</div>
													);
												})}
										</div>
									</Field>
								</div>
							</div>

							<div className="mrg-top-40 text-align">
								<Button
									variant={"outlined"}
									id="cancel-employee-btn"
									size={"medium"}
									color={"primary"}
									onClick={() => cancel()}
								>
									Cancel
								</Button>
								<Button
									disabled={isSubmitting}
									variant={"contained"}
									type={"submit"}
									size={"medium"}
									color={"primary"}
									className={
										isSubmitting
											? "has-loading-spinner mrg-left-20 pdd-left-30 pdd-right-30"
											: "mrg-left-20 pdd-left-30 pdd-right-30"
									}
								>
									{isSubmitting ? "Saving" : "Save"}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default EditShiftDetailsDialog;
