import * as Yup from "yup";

export const addShiftsValidation = Yup.object({
    title: Yup.string(),
    shift_dates: Yup.array().required("Required"),
    mode: Yup.string(),
    shift_type: Yup.string().required("Required"),
    hcp_type: Yup.string().required("Required"),
    hcp_speciality: Yup.string(),
    warning_type: Yup.string(),
    hcp_count: Yup.number().typeError("must be a number").min(1, "min limit 1.").max(25, "max limit 25.").required("Required"),
    hazard_rate: Yup.number().typeError("must be a number").min(0, "min limit 0.").max(99999, "max limit 99999.").required("Required"),
    shift_details: Yup.string().trim("empty space not allowed").max(750, "max limit 750").required("Required"),
    requested_by: Yup.string().required("Required")
});

let isEmail = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');

export const HomeAddShiftsValidation = Yup.object({
    title: Yup.string(),
    shift_date: Yup.date().typeError("must be date").nullable().required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string(),
    middle_name: Yup.string(),
    patients_gender: Yup.string().required("Required"),
    dob: Yup.string().typeError("must be date").nullable(),
    patient_mrn: Yup.string(),
    patient_phone_number: Yup.string().min(12, "min 10 digits").required("Required"),
    patient_email_address: Yup.string().email().matches(isEmail, "Invalid Email"),
    gender_preferences: Yup.string(),
    longitude: Yup.string().trim().matches(/^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,20})?))$/, "Please Enter Valid longitude").typeError("must be a number").required("Required"),
    latitude: Yup.string().trim().matches(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,20})?))$/, "Please Enter Valid latitude").typeError("must be a number").required("Required"),
    //language: Yup.string(),
    patient_street: Yup.string().required('Required'),
    //  visit_patient_by: Yup.string().required("Required"),
    number_of_visits: Yup.number(),
    patient_apt: Yup.string(),
    patient_city: Yup.string().required("Required"),
    patient_state: Yup.string().required("Required"),
    patient_zip_code: Yup.number().required("Required"),
    mode: Yup.string(),
    shift_type: Yup.string().required("Required"),
    hcp_type: Yup.string().required("Required"),
    hcp_speciality: Yup.string(),
    shift_rate: Yup.string().required("Required"),
    // hcp_count: Yup.number().typeError("must be a number").min(1, "min limit 1.").max(25, "max limit 25.").required("Required"),
    //  hazard_rate: Yup.number().typeError("must be a number").min(0, "min limit 0.").max(99999, "max limit 99999.").required("Required"),
    //  shift_details: Yup.string().trim("empty space not allowed").max(750, "max limit 750").required("Required"),
    //   requested_by: Yup.string().required("Required"),
});


export const ContractAddShiftsValidation = Yup.object({
    title: Yup.string(),
    shift_type: Yup.string().required("Required"),
    hcp_type: Yup.string().required("Required"),
    hcp_speciality: Yup.string(),
    start_date: Yup.date().typeError('Invalid Date').required("Required").nullable(),
    // end_date: Yup.date().nullable().typeError('Invalid Date').required("Required"),
    end_date: Yup.date().typeError('Invalid date')
        .test('is-greater-or-equal', 'End Date cannot be less than Start Date', function (end_date) {
            const {start_date} = this.parent; // Access the value of start_date
            // Perform the comparison
            if (start_date && end_date) {
                return new Date(end_date) >= new Date(start_date);
            }
            // If either value is missing, assume validation passes
            return true;
        }).required('Required').nullable(),
    hcp_count: Yup.number().typeError("must be a number").min(1, "min limit 1.").max(25, "max limit 25.").required("Required"),
    //  hazard_rate: Yup.number().typeError("must be a number").min(0, "min limit 0.").max(99999, "max limit 99999.").required("Required"),
    //shift_details: Yup.string().trim("empty space not allowed").max(750, "max limit 750").required("Required"),
    contract_requirements: Yup.object({
        days_per_week: Yup.number().typeError("must be a number").min(1, "min limit 1.").max(7, "max limit 7").required("Required"),
        shift_duration: Yup.string().required("Required"),
        contract_type: Yup.string().required("Required"),
        no_of_weeks: Yup.string().required("Required"),
        cancel_policy: Yup.string().required("Required"),
        // weekly_earning: Yup.number().typeError("must be a number").required("Required"),

    }),
    // travel_per_diems: Yup.array().of(
    //     Yup.object().shape({
    //         type: Yup.string().required('Type is required'),
    //         rate: Yup.number()
    //             .required('Rate is required')
    //             .positive('Rate must be a positive number'),
    //     })
    // ),
});


export const ApprovedContractAddShiftsValidation = Yup.object({

    contract_requirements: Yup.object({
        cancel_policy: Yup.string().required("Required"),
        // weekly_earning: Yup.number().typeError("must be a number").required("Required"),

    }),
});


export const PerDiemAddShiftsValidation = Yup.object({
  //  hcp_id: Yup.string().required("Required"),
    //  shift_dates: Yup.array().required("Required"),
    shift_type: Yup.string().required("Required"),
    shift_details: Yup.string().trim("empty space not allowed").max(750, "max limit 750").required("Required"),
});
