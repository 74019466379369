import React, {PropsWithChildren, useCallback, useState} from "react";
import NormalTextField from '@material-ui/core/TextField';
import FileDropZoneComponent from '../../../../components/core/FileDropZoneComponent';
import {Button, IconButton, Paper} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../../classes/ts-file-upload-wrapper.class";
import {ENV} from "../../../../constants";
import {CommonService} from "../../../../helpers";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CancelIcon from "@material-ui/icons/Cancel";
import DialogComponent from "../../../../components/DialogComponent";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";

export interface HcpEditAttachmentsComponentProps {
    fileUpload: any;
    required_attachments: any;
    isDeleted: any;
    OnFileSelected: any;
    attachmentsDetails: any;
    previewFile: any;
    handleExpiryDate: any;
    deleteLocalAttachment: any;
    openDeleteAttachment: any;
    setRequiredAttachments: any;
    setFileUpload: any;
    setOtherDocuments: any;
    otherDocuments: any;
}

const webMatColumns = ["Document Name", " ", "Delete"];
const HcpEditAttachmentsComponent = (props: PropsWithChildren<HcpEditAttachmentsComponentProps>) => {
    const attachmentsDetails = props?.attachmentsDetails;
    const required_attachments = props?.required_attachments;
    const handleExpiryDate = props?.handleExpiryDate;
    const fileUpload = props?.fileUpload;
    const previewFile = props?.previewFile;
    // const isDeleted = props?.isDeleted;
    const otherDocuments = props?.otherDocuments;
    const setOtherDocuments = props?.setOtherDocuments;
    // const OnFileSelected = props?.OnFileSelected;
    // const deleteLocalAttachment = props?.deleteLocalAttachment;
    const openDeleteAttachment = props?.openDeleteAttachment;
    const setRequiredAttachments = props?.setRequiredAttachments;
    const setFileUpload = props?.setFileUpload;
    // const [additionalCount, setAdditionalCount] = useState<any>(0);
    const [selectedDocument, setSelectedDocument] = useState<any>([]);
    //  const [otherDocuments, setOtherDocuments] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [apiData, setApiData] = useState<any>({});

    const deleteOtherFile = (temp: any, itemIndex: any) => {
        //setFileCount(fileCount - 1);
        const updatedOthersDocument = [...otherDocuments];
        updatedOthersDocument.splice(temp, 1);
        setOtherDocuments(updatedOthersDocument);
    };

    const OnFileSelected = (files: File[], index: any) => {
        setSelectedDocument(null);

        if (index < 14) {
            required_attachments[index].index = fileUpload?.wrapper?.length || 0;
            setRequiredAttachments([...required_attachments]);
        } else {
            setOtherDocuments((prevDocuments: any) => {
                const newDocuments = [...prevDocuments];
                for (let file of files) {
                    newDocuments.push({name: file.name, index: fileUpload?.wrapper?.length || 0});
                }
                return newDocuments;
            });
        }
        for (let file of files) {
            // console.log(file)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: 'Data',
                uploadUrl: ENV.API_URL + 'facility/add',
                allowed_types: ['jpg', 'png', 'csv', 'pdf'],
                extraPayload: {expiry_date: '', file_type: required_attachments[index]?.attachment_type}
            };
            const uploadWrapper = new TsFileUploadWrapperClass(uploadConfig, CommonService._api, (state: {
                wrapper: TsFileUploadWrapperClass
            }) => {
                // console.log(state);
                setFileUpload((prevState: any) => {
                    if (prevState) {
                        const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                        prevState.wrapper[index] = state.wrapper;
                        return {wrapper: prevState.wrapper};
                    }
                    return prevState;
                });
            });
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, 'error');
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, 'success');
                }
            };
            uploadWrapper.onProgress = (progress) => {
            };
            setFileUpload((prevState: any) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
        }
    };

    const previewApiFile = useCallback((item: any) => {
        setApiData(item);
        setOpen(true);
    }, []);
    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);
    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const handleAttachmentName = (index: any, RequiredAttachmentsIndex: any) => {
        setFileUpload((prevState: any) => {
            if (prevState) {
                prevState.wrapper[RequiredAttachmentsIndex].extraPayload.file_type = prevState.wrapper[RequiredAttachmentsIndex].extraPayload.expiry_date;
            }
            return {wrapper: [...(prevState || {wrapper: []}).wrapper]};
        });
    };

    const deleteFile = (temp: any, itemIndex: any) => {
        //  setFileCount(fileCount - 1);
        if (required_attachments[temp]) {
            required_attachments[temp].index = -1;
            setRequiredAttachments([...required_attachments]);
        }

    };
    const handleOthersAttachmentName = (index: any, RequiredAttachmentsIndex: any) => {
        setFileUpload((prevState: any) => {
            if (prevState) {
                if (required_attachments?.some((item: any) => item?.attachment_type === prevState.wrapper[index].extraPayload.expiry_date)) {
                    CommonService.showToast("File name already exists, Please change it", "info");
                } else {
                    prevState.wrapper[index].extraPayload.file_type = prevState.wrapper[index].extraPayload.expiry_date;
                }
            }
            return {wrapper: [...(prevState || {wrapper: []}).wrapper]};
        });
    };

    const handleOtherAttachmentName = (event: any, index: any) => {
        setFileUpload((prevState: any) => {
            if (prevState) {
                prevState.wrapper[index].extraPayload.doc_name = event.target.value;
            }
            return {wrapper: [...(prevState || {wrapper: []}).wrapper]};
        });
    };

    function SelectDocumentDropdown() {
        // let filteredData = required_attachments?.filter((item: any) => !attachmentsDetails?.some((item2: any) => item?.attachment_type === item2?.attachment_type));
        let filteredData = required_attachments?.slice(0, -1)?.filter((item: any) =>
            !attachmentsDetails?.some((item2: any) => item?.attachment_type === item2?.attachment_type)
        );

        if (required_attachments && required_attachments.length > 0) {
            filteredData = filteredData.concat(required_attachments.slice(-1));
        }
        return (
            <>

                {
                    <Autocomplete
                        PaperComponent={({children}) => <Paper style={{color: "#1e1e1e"}}>{children}</Paper>}
                        value={selectedDocument}
                        options={filteredData}
                        getOptionLabel={(option: any) => option?.attachment_type}
                        placeholder={"Select Status"}
                        id="input_select_status"
                        className="mrg-top-10"
                        onChange={($event, value) => {
                            setSelectedDocument(value);
                        }}
                        renderInput={(params) => <TextField {...params} id="select_status" variant="outlined"
                                                            value={selectedDocument} placeholder={"Select Document"}
                                                            fullWidth/>}
                    />
                }
                {required_attachments?.map((item: any, index: any) => {
                    if ((selectedDocument?.attachment_type === "Others" && index === 14) || (selectedDocument?.attachment_type === item?.attachment_type && index < 14)) {
                        return (
                            <div className="attachments_wrapper">
                                <div className="attachments">
                                    <div className="">
                                        <h3 className="attachement_name mrg-left-10 file_attachment_title">{item?.attachment_type}</h3>
                                        {/*<Tooltip title={`Upload ${item?.name}`}>*/}
                                        <div>
                                            <FileDropZoneComponent
                                                OnFileSelected={(item) => OnFileSelected(item, index)}
                                                allowedTypes={".pdf"}
                                            />
                                        </div>
                                        {/*</Tooltip>*/}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </>
        );
    }


    function RenderSortedAttachments() {
        return (
            <>
                <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                    <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile} previewData={apiData}/>
                </DialogComponent>
                <div className={"mrg-top-20"}>
                    {(required_attachments.some((attachment: any) => attachment.index !== -1) || otherDocuments?.length !== 0 || attachmentsDetails?.length !== 0) &&
                        <TableContainer component={Paper} className={"doc-table-responsive"}>
                            <Table size={"small"} stickyHeader
                                   className="mat-table table shifts-requirment-pending-list-table">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        {webMatColumns.map((column: any, columnIndex: any) => (
                                            <TableCell
                                                className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                key={"header-col-" + columnIndex}>
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {required_attachments?.map((item: any, index: any) => {
                                        if (item.index !== -1) {
                                            return (<>
                                                    <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                              key={`row-${index}`}>
                                                        <TableCell
                                                            className="mat-td mat-td-hcp-name">
                                                            <p style={{
                                                                cursor: 'pointer',
                                                                textUnderlinePosition: "under"
                                                            }}
                                                               onClick={() => previewFile(item?.index, "attachment")}
                                                               className="delete-image"> {required_attachments[index].attachment_type}</p>
                                                        </TableCell>
                                                        <TableCell
                                                            className="mat-td mat-td-hcp-email">
                                                            {index <= 15 ?
                                                                index === 1124 || index === 1222 || index === 2229 ?
                                                                    <div></div> :
                                                                    <NormalTextField
                                                                        required
                                                                        label={"Expiry Date"}
                                                                        type={"date"}
                                                                        InputLabelProps={{shrink: true}}
                                                                        onChange={(event) => handleExpiryDate(event, required_attachments[index]?.index)}
                                                                        value={fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload?.expiry_date}
                                                                        //  disabled={index === 0 || index === 6}
                                                                        inputProps={{
                                                                            max: '2999-01-01'
                                                                        }}
                                                                    /> :
                                                                <div className="d-flex">
                                                                    <NormalTextField
                                                                        required
                                                                        label="Attachment Name"
                                                                        type={"text"}
                                                                        inputProps={{maxLength: 30}}
                                                                        InputLabelProps={{shrink: true}}
                                                                        onChange={(event) => handleExpiryDate(event, required_attachments[index]?.index)}
                                                                        value={fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload?.expiry_date}
                                                                    />
                                                                    <div className="mrg-top-15 mrg-left-15"><Button
                                                                        color="primary"
                                                                        variant={"outlined"}
                                                                        onClick={() => handleAttachmentName(required_attachments[index]?.index, index)}>Save</Button>
                                                                    </div>
                                                                </div>}
                                                        </TableCell>
                                                        <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                            <div>
                                                                {/*<IconButton onClick={() => openRejectApplication(row["_id"])} disabled={status === "cancelled"}>*/}
                                                                {/*    <CancelIcon className="delete-icon" />*/}
                                                                {/*</IconButton>*/}
                                                                <IconButton
                                                                    onClick={() => deleteFile(index, item?.index)}>
                                                                    <CancelIcon className="delete-icon"/>
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                                ;
                                        }
                                        return null;
                                    })}
                                </TableBody>
                                <TableBody className={"mat-tbody"}>
                                    {otherDocuments?.map((item: any, index: any) => {
                                        return (<>
                                                <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                          key={`row-${index}`}>
                                                    <TableCell
                                                        className="mat-td mat-td-hcp-name">
                                                        <p style={{cursor: 'pointer', textUnderlinePosition: "under"}}
                                                           onClick={() => previewFile(item?.index, "attachment")}
                                                           className="delete-image"> {otherDocuments[index].name}</p>
                                                    </TableCell>
                                                    {/*<TableCell*/}
                                                    {/*    className="mat-td mat-td-hcp-email">*/}
                                                    {/*    <div className="d-flex">*/}
                                                    {/*        <NormalTextField*/}
                                                    {/*            required*/}
                                                    {/*            label="Attachment Name"*/}
                                                    {/*            type={"text"}*/}
                                                    {/*            inputProps={{maxLength: 30}}*/}
                                                    {/*            InputLabelProps={{shrink: true}}*/}
                                                    {/*            onChange={(event) => handleOtherAttachmentName(event, otherDocuments[index]?.index)}*/}
                                                    {/*            value={fileUpload?.wrapper[otherDocuments[index]?.index]?.extraPayload?.expiry_date}*/}
                                                    {/*        />*/}
                                                    {/*        <div className="mrg-top-15 mrg-left-15"><Button*/}
                                                    {/*            color="primary"*/}
                                                    {/*            variant={"outlined"}*/}
                                                    {/*            onClick={() => handleOthersAttachmentName(otherDocuments[index]?.index, index)}>Save</Button>*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*</TableCell>*/}
                                                    <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                        <div className="d-flex">
                                                            <div className={'mrg-right-10'}>
                                                                <NormalTextField
                                                                    required
                                                                    label="Expiry Date"
                                                                    type={"date"}
                                                                    InputLabelProps={{shrink: true}}
                                                                    onChange={(event) => handleExpiryDate(event, otherDocuments[index]?.index)}
                                                                    value={fileUpload?.wrapper[otherDocuments[index]?.index]?.extraPayload?.expiry_date}
                                                                    //  disabled={index === 0 || index === 6}
                                                                    inputProps={{
                                                                        max: '2999-01-01'
                                                                    }}
                                                                />
                                                            </div>
                                                            <NormalTextField
                                                                required
                                                                label="Attachment Name"
                                                                type={"text"}
                                                                inputProps={{maxLength: 30}}
                                                                InputLabelProps={{shrink: true}}
                                                                onChange={(event) => handleOtherAttachmentName(event, otherDocuments[index]?.index)}
                                                                value={fileUpload?.wrapper[otherDocuments[index]?.index]?.extraPayload?.doc_name}
                                                            />
                                                            <div className="mrg-top-15 mrg-left-15"><Button
                                                                color="primary"
                                                                variant={"outlined"}
                                                                onClick={() => handleOthersAttachmentName(otherDocuments[index]?.index, index)}>Save</Button>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                        <div>
                                                            {/*<IconButton onClick={() => openRejectApplication(row["_id"])} disabled={status === "cancelled"}>*/}
                                                            {/*    <CancelIcon className="delete-icon" />*/}
                                                            {/*</IconButton>*/}
                                                            <IconButton
                                                                onClick={() => deleteOtherFile(index, item?.index)}>
                                                                <CancelIcon className="delete-icon"/>
                                                            </IconButton>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        );
                                    })}
                                </TableBody>
                                <TableBody className={"mat-tbody"}>

                                    {attachmentsDetails?.map((item: any, index: any) => {
                                        return (<>
                                                <TableRow className="mat-tr" role="checkbox" tabIndex={-1}
                                                          key={`row-${index}`}>
                                                    <TableCell
                                                        className="mat-td mat-td-hcp-name">
                                                        <p style={{cursor: 'pointer', textUnderlinePosition: "under"}}
                                                           onClick={() => {
                                                               previewApiFile(item);
                                                           }}
                                                           className="delete-image"> {item.attachment_type}</p>
                                                    </TableCell>
                                                    <TableCell
                                                        className="mat-td mat-td-hcp-email">
                                                        <div className="d-flex">
                                                            {item?.expiry_date ?
                                                                <NormalTextField
                                                                    label="Expires On"
                                                                    type={"date"}
                                                                    InputLabelProps={{shrink: true}}
                                                                    onChange={(event) => handleExpiryDate(event, required_attachments[index]?.index)}
                                                                    disabled
                                                                    inputProps={{
                                                                        max: '2999-01-01'
                                                                    }}
                                                                    value={item.expiry_date}
                                                                    placeholder="MM-DD-YYYY"
                                                                /> : <div className=" mrg-top-45"></div>}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                        <div>
                                                            <IconButton onClick={(e) => openDeleteAttachment(e, item)}>
                                                                <CancelIcon className="delete-icon"/>
                                                            </IconButton>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                </div>
            </>
        );
    }

    return <div className="">
        {SelectDocumentDropdown()}
        {RenderSortedAttachments()}
    </div>;
};

export default HcpEditAttachmentsComponent;


