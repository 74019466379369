import {Avatar, Button, Checkbox, MenuItem, TextField, Tooltip} from "@material-ui/core";
import {Field, Form, Formik, FormikHelpers} from "formik";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import * as Yup from "yup";
import DialogComponent from "../../../../components/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import {ENV} from "../../../../constants";
import {isHCPPaid as hcpPaidOptions} from "../../../../constants/data";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import EditShiftDetailsDialog from "../../editShiftDetailsDialog/EditShiftDetailsDialog";
import {showDropDownBelowField} from "../../shiftMaster/view/ShiftMasterValidations";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../../classes/ts-file-upload-wrapper.class";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import "./CancelledShiftsViewScreen.scss";
import FileDropZoneComponent from "../../../../components/core/FileDropZoneComponent";
import ApprovedCancelledComponentDetails
    from "../../approved-cancelled-shift-details/ShiftDetailsApprovedCancelledComponent";

const CancelledShiftsViewScreen = () => {
    const param = useParams<any>();
    const {id} = param;
    const [basicDetails, setBasicDetails] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [fileUpload, setFileUpload] = useState<{ wrapper: any } | null>(null);
    const [required_attachments, setRequiredAttachments] = useState<any>([{name: "Timesheet", index: -1}]);
    const [open, setOpen] = useState<boolean>(false);
    //const [downloadAttachmentsList, downloadSeAttachmentsList] = useState<any | null>(null);
    const [isAttachhmentsDownloadLoading] = useState<boolean>(false);
    const [attachmentsList, setAttachmentsList] = useState<any>(null);
    const [previewFileData, setPreviewFile] = useState<any | null>(null);
    const [isTimeSheetBeingUpdated, setIsTimeSheetBeingUpdated] = useState<boolean>(false);
    const [isAttachmentsLoading] = useState<boolean>(false);
    const [remarks, setRemarks] = useState('');


    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isHCPBilled, setIsHCPBilled] = useState<boolean | null>(null);
    const [isEditShiftOpen, setIsEditShiftOpen] = useState<boolean>(false);
    const [isFacilityConfirm, setIsFacilityConfirm] = useState<boolean>(false);

    const handleFacilityConfirmation = (e: any) => {
        setIsFacilityConfirm(e.target.checked);
    };

    const paymentInitialState = {
        facility_billed_details: {
            is_billed: basicDetails?.facility_billed_details?.hasOwnProperty("is_billed")
                ? basicDetails?.facility_billed_details?.is_billed
                : "",
            invoice_date: basicDetails?.facility_billed_details?.invoice_date ?? null,
            comments: basicDetails?.facility_billed_details?.hasOwnProperty("comments")
                ? basicDetails?.facility_billed_details?.comments
                : "",
        },
    };

    const paymentValidation = Yup.object({
        facility_billed_details: Yup.object({
            is_billed: Yup.mixed().nullable(),
            invoice_date: Yup.date()
                .typeError("Required")
                .when("is_billed", {
                    is: true,
                    then: Yup.date().required("Required").nullable(),
                })
                .when("is_billed", {
                    is: false,
                    then: Yup.date().nullable(),
                }),
            comments: Yup.string().nullable(),
        }),
    });

    const getShiftDetails = useCallback(() => {
        CommonService._api
            .get(ENV.API_URL + "shift/" + id)
            .then((resp) => {
                setBasicDetails(resp.data);
                setIsHCPBilled(resp.data?.facility_billed_details?.is_billed);
                setIsFacilityConfirm(resp.data?.is_facility_approved);
                setRemarks(resp?.data?.remarks);
                setIsLoading(false);
                if (resp?.data?.timesheet_details?.url !== undefined) {
                    setAttachmentsList([resp?.data?.timesheet_details]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id]);

    const OnFileSelected = (files: File[], index: any) => {
        if (required_attachments[index]) {
            required_attachments[index].index = fileUpload?.wrapper?.length || 0;
            setRequiredAttachments([...required_attachments]);
        }
        for (let file of files) {
            // console.log(file)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf", "jpeg"],
                extraPayload: {file_type: required_attachments[index]?.name},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(
                uploadConfig,
                CommonService._api,
                (state: { wrapper: TsFileUploadWrapperClass }) => {
                    // console.log(state);
                    setFileUpload((prevState) => {
                        if (prevState) {
                            const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                            prevState.wrapper[index] = state.wrapper;
                            return {wrapper: prevState.wrapper};
                        }
                        return prevState;
                    });
                }
            );
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
            };
            setFileUpload((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
        }
    };

    const deleteFile = (temp: any) => {
        let data = fileUpload?.wrapper.filter((_: any, index: any) => index !== temp);
        if (required_attachments[temp]) {
            required_attachments[temp].index = -1;
            setRequiredAttachments([...required_attachments]);
        }
        setFileUpload((prevState) => {
            return {wrapper: [...data]};
        });
    };

    const previewFile = useCallback(
        (index: any, type: any) => {
            setOpen(true);
            if (type === "local") {
                setPreviewFile(fileUpload?.wrapper[0]);
            } else {
                setPreviewFile(attachmentsList[index]);
            }
        },
        [attachmentsList, fileUpload?.wrapper]
    );

    const handleDeleteTimesheet = useCallback(() => {
        if (attachmentsList.length > 0) {
            const shiftId = basicDetails?._id;
            const file_key = attachmentsList[0].file_key;
            ApiService.delete(ENV.API_URL + `shift/${shiftId}/timesheet`, {
                file_key,
            })
                .then((res) => {
                    CommonService.showToast(res?.msg || `Timesheet deleted successfully,`, "success");
                    setAttachmentsList([]);
                })
                .catch((err) => {
                    CommonService.showToast(err?.message || `Error,`, "error");
                    console.log(err);
                });
        }
    }, [attachmentsList, basicDetails?._id]);

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    // const getShiftAttachments = useCallback(() => {
    // 	setIsAttachmentsLoading(true);
    //
    // 	CommonService._api
    // 		.get(ENV.API_URL + "shift/" + id + "/attachments?is_preview=true")
    // 		.then((resp) => {
    // 			seAttachmentsList(resp?.data || []);
    // 			setIsAttachmentsLoading(false);
    // 		})
    // 		.catch((err) => {
    // 			console.log(err);
    // 			setIsAttachmentsLoading(false);
    // 		});
    // }, [id]);
    //
    // const getShiftAttachmentsDownload = useCallback(() => {
    // 	setIsAttachhmentsDownloadLoading(true);
    //
    // 	CommonService._api
    // 		.get(ENV.API_URL + "shift/" + id + "/attachments")
    // 		.then((resp) => {
    // 			downloadSeAttachmentsList(resp.data);
    // 			setIsAttachhmentsDownloadLoading(false);
    // 		})
    // 		.catch((err) => {
    // 			console.log(err);
    // 			setIsAttachhmentsDownloadLoading(false);
    // 		});
    // }, [id]);

    useEffect(() => {
        getShiftDetails();
    }, [getShiftDetails]);

    useEffect(() => {
        Communications.pageTitleSubject.next("Cancelled");
        Communications.pageBackButtonSubject.next("/cancelledShifts/list");
    }, []);

    const handleConfirmationFromFacility = useCallback(() => {
        return new Promise((resolve, reject) => {
            ApiService.put(ENV.API_URL + "shift/" + id, {
                is_facility_approved: isFacilityConfirm,
            })
                .then((res: any) => {
                    resolve(null);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, [id, isFacilityConfirm]);

    const handleGetUrlForUpload = useCallback(() => {
        return new Promise((resolve, reject) => {
            const file = fileUpload?.wrapper[0]?.file;
            const formData = new FormData();
            if (file) {
                formData.append("attachment", file);
                formData.append("attachment_type", fileUpload?.wrapper[0]?.extraPayload?.file_type);
            }
            if (fileUpload?.wrapper.length > 0) {
                setIsTimeSheetBeingUpdated(true);
                CommonService._api
                    .upload(ENV.API_URL + "shift/" + id + "/timesheet", formData)
                    .then((resp) => {
                        resolve(resp)
                        setIsTimeSheetBeingUpdated(false);
                        CommonService.showToast(resp?.msg || "attachment uploaded", "success");
                    })
                    .catch((err) => {
                        resolve(err)
                        CommonService.showToast(err?.error || "Error", "error");
                        setIsTimeSheetBeingUpdated(false);
                    });
            } else {
                resolve(null);
            }
        });
    }, [fileUpload?.wrapper, id]);


    const onAdd = (payload: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<any>) => {
        setIsSubmitting(true);

        Promise.all([handleConfirmationFromFacility(), handleShiftDataEdit(payload), handleGetUrlForUpload()])
            .then((res: any) => {
                CommonService.showToast(res?.msg || "Success", "success");
                setIsSubmitting(false);
                resetForm();
                history.push("/cancelledShifts/list");
            })
            .catch((err) => {
                CommonService.showToast(err?.error || "Error", "error");
                setIsSubmitting(false);
            });
    };

    const handleShiftDataEdit = (payload: any) => {
        const {facility_billed_details} = payload;
        const {invoice_date, is_billed, comments: facility_comments} = facility_billed_details;

        return new Promise((resolve, reject) => {
            ApiService.put(ENV.API_URL + "shift/" + id, {
                facility_comments,
                invoice_date: invoice_date ? moment(invoice_date).format("YYYY-MM-DD") : null,
                is_billed, remarks
            })
                .then((res: any) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    };

    const getUpdatedShiftData = useCallback(() => {
        return new Promise((resolve, reject) => {
            CommonService._api
                .get(ENV.API_URL + "shift/" + id)
                .then((resp) => {
                    setBasicDetails(resp?.data);
                    resolve(null);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                    setIsLoading(false);
                });
        });
    }, [id]);

    const openEditShift = useCallback(() => {
        setIsEditShiftOpen(true);
    }, []);

    const cancelopenEditShift = useCallback(() => {
        setIsEditShiftOpen(false);
    }, []);

    const confirmopenEditShift = useCallback(() => {
        getUpdatedShiftData().then((res) => setIsEditShiftOpen(false));
    }, [getUpdatedShiftData]);


    if (isLoading || isAttachmentsLoading || isAttachhmentsDownloadLoading) {
        return <LoaderComponent/>;
    }

    return (
        <div className="shift-cancelled-view screen crud-layout pdd-30">
            <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                   previewData={previewFileData}/>
            </DialogComponent>
            <DialogComponent open={isEditShiftOpen} cancel={cancelopenEditShift} maxWidth="sm">
                <EditShiftDetailsDialog
                    shiftDetails={basicDetails}
                    cancel={cancelopenEditShift}
                    confirm={confirmopenEditShift}
                    shiftId={id}
                />
            </DialogComponent>

            {!isLoading && (
                <>
                    <div className="pdd-0 custom-border">
                        <div className="d-flex pdd-20 hcp-photo-details-wrapper">
                            <div className="d-flex">
                                <div className="flex-1">
                                    <Avatar
                                        alt="user photo"
                                        style={{height: "80px", width: "80px"}}
                                        src={
                                            "https://images.unsplash.com/photo-1510832198440-a52376950479?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Z2lybHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80"
                                        }
                                    />
                                </div>
                                <div className="hcp-name-type">
                                    <h2>
                                        {basicDetails?.hcp_details?.first_name}&nbsp;{basicDetails?.hcp_details?.last_name}
                                    </h2>
                                    <p>{basicDetails?.hcp_details?.hcp_type}</p>
                                </div>
                            </div>
                            <div className="ratings"></div>
                        </div>
                        <div className="d-flex hcp-details pdd-bottom-20 custom-border " style={{gap: "20px"}}>
                            <div className="flex-1">
                                <h4>Years Of Experience</h4>
                                <p>
                                    {basicDetails?.hcp_details?.professional_details?.experience
                                        ? basicDetails?.hcp_details?.professional_details?.experience + " Years"
                                        : basicDetails?.hcp_details?.professional_details?.experience === 0
                                            ? 0 + " Years"
                                            : "N/A"}
                                </p>
                            </div>
                            <div className="flex-1">
                                <h4>Contact Number</h4>
                                <p>{basicDetails?.hcp_details?.contact_number}</p>
                            </div>
                            <div className="flex-1">
                                <h4>Address</h4>
                                <p>
                                    {basicDetails?.hcp_details?.address?.region},&nbsp;{basicDetails?.hcp_details?.address?.city},&nbsp;
                                    {basicDetails?.hcp_details?.address?.state},&nbsp;{basicDetails?.hcp_details?.address?.country},&nbsp;
                                    {basicDetails?.hcp_details?.address?.zip_code}&nbsp;&nbsp;
                                </p>
                            </div>
                            <div className="flex-1">
                                <h4>Email</h4>
                                <p>{basicDetails?.hcp_details?.email}</p>
                            </div>
                            <div className="flex-1">
                                <h4>Staff Rate (hr)</h4>
                                <p>{basicDetails?.hcp_details?.contract_details?.rate_per_hour ?? 0} $</p>
                            </div>
                        </div>
                    </div>
                    <div className="mrg-top-10 custom-border pdd-top-10">
                        <div className="">
                            <h2>Reason for Cancellation</h2>
                            <p>{basicDetails?.cancelled_details?.reason}</p>
                        </div>
                        <div className="reject-by-wrapper d-flex">
                            <div>
                                <h3>Cancelled By:</h3>
                                <p>
                                    {basicDetails?.cancelled_details?.cancelled_by?.first_name} &nbsp;{" "}
                                    {basicDetails?.cancelled_details?.cancelled_by?.last_name}
                                </p>
                            </div>
                            <div className="mrg-left-50">
                                <h3>Role:</h3>
                                <p>{basicDetails?.cancelled_details?.cancelled_by?.role}</p>
                            </div>
                            <div className="mrg-left-50">
                                <h3>Cancelled Date:</h3>
                                <p>
                                    {basicDetails?.cancelled_details?.cancelled_date
                                        ? moment(basicDetails?.cancelled_details?.cancelled_date).format("MM-DD-YYYY")
                                        : "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="facility-details custom-border mrg-top-10">
                        <h2>{basicDetails?.facility?.name}</h2>
                        <p>
                            {basicDetails?.facility?.address?.street},&nbsp;{basicDetails?.facility?.address?.region},&nbsp;
                            {basicDetails?.facility?.address?.city},&nbsp;{basicDetails?.facility?.address?.country},&nbsp;
                            {basicDetails?.facility?.address?.zip_code}.
                        </p>
                    </div>


                    {/*<div className="facility-details mrg-top-10 custom-border">*/}
                    {/*    <div className="d-flex shift-name-requested">*/}
                    {/*        <div className="shift-name-action-container d-flex">*/}
                    {/*            <h2>Shift Details</h2>*/}
                    {/*            <h4 className="shift-detail-edit" onClick={openEditShift}>*/}
                    {/*                (Edit)*/}
                    {/*            </h4>*/}
                    {/*        </div>*/}
                    {/*        <div className="d-flex requested-on-wrapper">*/}
                    {/*            <h3>Created On:</h3>*/}
                    {/*            <p className="mrg-left-10">{moment(basicDetails?.created_at).format("MM-DD-YYYY")}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <p>{basicDetails?.title}</p>*/}
                    {/*    <div className="d-flex shift-details">*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Required On:</h3>*/}
                    {/*            <p>{shift_date}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Time</h3>*/}
                    {/*            <p>*/}
                    {/*                {moment(basicDetails?.expected?.shift_start_time).format("hh:mm A")} &nbsp;-&nbsp;*/}
                    {/*                {moment(basicDetails?.expected?.shift_end_time).format("hh:mm A")}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Shift Type:</h3>*/}
                    {/*            <p>{basicDetails?.shift_type}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Warning Zone</h3>*/}
                    {/*            <p>{basicDetails?.warning_type}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-flex shift-details">*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>HCP Differential Rate</h3>*/}
                    {/*            <p>{basicDetails?.payments?.differential ? basicDetails?.payments?.differential : "NA"}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Rush Rate</h3>*/}
                    {/*            <p>{basicDetails?.requirement?.rush_rate ? basicDetails?.requirement?.rush_rate : "NA"}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Weekend Rate</h3>*/}
                    {/*            <p>{basicDetails?.requirement?.weekend_rate ? basicDetails?.requirement?.weekend_rate : "NA"}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>NOC Diff</h3>*/}
                    {/*            <p>{basicDetails?.requirement?.noc_diff ? basicDetails?.requirement?.noc_diff : "NA"}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="d-flex shift-details">*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>PM Diff</h3>*/}
                    {/*            <p>{basicDetails?.requirement?.pm_diff ? basicDetails?.requirement?.pm_diff : "NA"}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Shift Rate</h3>*/}
                    {/*            <p>{basicDetails?.requirement?.shift_rate ? basicDetails?.requirement?.shift_rate : "NA"}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>HCP Hourly Rate</h3>*/}
                    {/*            <p>{basicDetails?.payments?.hourly_hcp}</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Hazard Rate</h3>*/}
                    {/*            <p>{basicDetails?.hasOwnProperty("hazard_rate") ? basicDetails?.hazard_rate : "NA"}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="d-flex shift-details">*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>HCP Pay Date</h3>*/}
                    {/*            <p>*/}
                    {/*                {basicDetails?.hasOwnProperty("hcp_pay_date")*/}
                    {/*                    ? basicDetails?.hcp_pay_date*/}
                    {/*                        ? moment(basicDetails?.hcp_pay_date).format("MM-DD-YYYY")*/}
                    {/*                        : "NA"*/}
                    {/*                    : "NA"}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>HCP Speciality</h3>*/}
                    {/*            <p>{basicDetails?.hasOwnProperty("hcp_speciality") ? basicDetails?.hcp_speciality ?? "NA" : "NA"}</p>*/}
                    {/*        </div>*/}

                    {/*        <div className="flex-1">*/}
                    {/*            <h3>HCP Sent Home</h3>*/}
                    {/*            <p>*/}
                    {/*                {basicDetails?.hasOwnProperty("is_sent_home")*/}
                    {/*                    ? basicDetails?.is_sent_home === true*/}
                    {/*                        ? "Yes"*/}
                    {/*                        : "No"*/}
                    {/*                    : "NA"}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Salary Credit Day</h3>*/}
                    {/*            <p>{basicDetails?.hasOwnProperty("hcp_details") ? (basicDetails?.hcp_details?.contract_details?.salary_credit ?? "NA") : "NA"}</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-flex shift-details">*/}
                    {/*        <div className="flex-1">*/}
                    {/*            <h3>Show Shift Rate in Mobile ?</h3>*/}
                    {/*            <p>*/}
                    {/*                {basicDetails?.requirement?.is_shift_rate ? "Yes" : "No"}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <ApprovedCancelledComponentDetails basicDetails={basicDetails} openEditShift={openEditShift}/>


                    {/*{basicDetails?.requirement?.type === "home" &&*/}
                    {/*    <div className="facility-details custom-border mrg-top-10">*/}
                    {/*        <div className={'d-flex'}>*/}
                    {/*            <h3 className={'mrg-right-5'}>{basicDetails?.requirement?.patient_details?.first_name}</h3>*/}
                    {/*            <h3 className={'mrg-right-5'}>{basicDetails?.requirement?.patient_details?.middle_name}</h3>*/}
                    {/*            <h3>{basicDetails?.requirement?.patient_details?.last_name}</h3>*/}
                    {/*            {*/}
                    {/*                <h3 className={"mrg-left-10"}>*/}
                    {/*                    ({CommonService.calculateAge(basicDetails?.requirement?.patient_details?.dob)}years, {basicDetails && basicDetails?.patient_details?.gender})*/}
                    {/*                </h3>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*        <p>*/}
                    {/*            {basicDetails?.requirement?.patient_details?.address?.apt && basicDetails?.requirement?.patient_details?.address?.apt + ","}&nbsp;*/}
                    {/*            {basicDetails?.requirement?.patient_details?.address?.street && basicDetails?.requirement?.patient_details?.address?.street + ","}&nbsp;*/}
                    {/*            {basicDetails?.requirement?.patient_details?.address?.city && basicDetails?.requirement?.patient_details?.address?.city + ","}&nbsp;*/}
                    {/*            {basicDetails?.requirement?.patient_details?.address?.state && basicDetails?.requirement?.patient_details?.address?.state + ","}&nbsp;*/}
                    {/*            {basicDetails?.requirement?.patient_details?.address?.zip_code && basicDetails?.requirement?.patient_details?.address?.zip_code}&nbsp;*/}
                    {/*        </p>*/}
                    {/*        <p>*/}
                    {/*            MRN no : {basicDetails && basicDetails?.requirement?.patient_details?.mrn}*/}
                    {/*        </p>*/}

                    {/*        <div className={'mrg-top-25 d-flex'} style={{*/}
                    {/*            color: "#808080"*/}
                    {/*        }}>*/}
                    {/*            <div>*/}
                    {/*                <span>Phone Number : {basicDetails?.requirement?.patient_details?.contact_number}</span>*/}
                    {/*            </div>*/}

                    {/*            <div className={'d-flex'}>*/}
                    {/*                <span>&nbsp;| Email Address : {basicDetails?.requirement?.patient_details?.email}</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>}*/}


                    {/*<div className="facility-details mrg-top-10 custom-border">*/}
                    {/*    {basicDetails?.requirement?.type === "home" &&*/}
                    {/*        <>*/}
                    {/*            <div className="d-flex shift-name-requested">*/}

                    {/*                <div className="shift-name-action-container d-flex">*/}
                    {/*                    <h2>Shift Details</h2>*/}
                    {/*                    /!*<h4 className="shift-detail-edit" onClick={openEditShift}>(Edit)</h4>*!/*/}
                    {/*                </div>*/}

                    {/*                <div className="d-flex requested-on-wrapper">*/}
                    {/*                    <h3>Created On:</h3>*/}
                    {/*                    <p className="mrg-left-10">{moment(basicDetails?.created_at).format("MM-DD-YYYY")}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <p>{basicDetails?.title}</p>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Required On</h3>*/}
                    {/*                    <p>{CommonService.getUtcDate(basicDetails?.shift_date)}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Shift Time</h3>*/}
                    {/*                    <p>*/}
                    {/*                        {basicDetails?.expected?.shift_start_time*/}
                    {/*                            ? moment(basicDetails.expected.shift_start_time).format("hh:mm A")*/}
                    {/*                            : "N/A"}*/}
                    {/*                        &nbsp;-&nbsp;*/}
                    {/*                        {basicDetails?.expected?.shift_end_time*/}
                    {/*                            ? moment(basicDetails.expected.shift_end_time).format("hh:mm A")*/}
                    {/*                            : "N/A"}*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}

                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Shift Type</h3>*/}
                    {/*                    <p>{basicDetails?.shift_type}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>HCP Type</h3>*/}
                    {/*                    <p>{basicDetails?.hcp_type}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>No. of HCPs Required</h3>*/}
                    {/*                    <p>{basicDetails?.hcp_count || "NA"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className={'flex-1'}>*/}
                    {/*                    <>*/}
                    {/*                        <h3>Requested By</h3>*/}
                    {/*                        <p>{basicDetails?.requested_by || "NA"}</p>*/}
                    {/*                    </>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <>*/}
                    {/*                        <h3>Number of Visits</h3>*/}
                    {/*                        <p>{basicDetails?.requirement?.home_requirements?.number_of_visits || "NA"}</p>*/}
                    {/*                    </>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Show Shift Rate in Mobile ?</h3>*/}
                    {/*                    <p>*/}
                    {/*                        {basicDetails?.is_shift_rate*/}
                    {/*                            ? basicDetails?.is_shift_rate === "true" || basicDetails?.is_shift_rate === true*/}
                    {/*                                ? "Yes"*/}
                    {/*                                : "No"*/}
                    {/*                            : "NA"}*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Shift Rate</h3>*/}
                    {/*                    <p>$ {basicDetails?.requirement?.shift_rate || "NA"}</p>*/}
                    {/*                </div>*/}

                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>HCP Speciality</h3>*/}
                    {/*                    <p>{basicDetails?.hasOwnProperty("hcp_speciality") ? (basicDetails?.hcp_speciality ? basicDetails?.hcp_speciality : "NA") : "NA"}</p>*/}

                    {/*                </div>*/}


                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Created By</h3>*/}
                    {/*                    <p>{basicDetails?.requirement_owner_details ? (basicDetails?.requirement_owner_details?.first_name + " " + basicDetails?.requirement_owner_details?.last_name) : "NA"}</p>*/}

                    {/*                </div>*/}

                    {/*                <div className="flex-1">*/}
                    {/*                    <>*/}
                    {/*                        <h3>Type</h3>*/}
                    {/*                        <p>{basicDetails?.requirement?.type || "NA"}</p>*/}
                    {/*                    </>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <>*/}
                    {/*                        <h3>Language</h3>*/}
                    {/*                        <p>{basicDetails?.requirement?.home_requirements?.languages[0] ? CommonService.capitalizeFirstLetter(basicDetails?.requirement?.home_requirements?.languages[0]) : "NA"}</p>*/}
                    {/*                    </>*/}
                    {/*                </div>*/}

                    {/*                <div className="flex-1">*/}
                    {/*                    <>*/}
                    {/*                        <h3>Gender Preference</h3>*/}
                    {/*                        <p>{basicDetails?.requirement?.home_requirements?.gender_pref ? CommonService.capitalizeFirstLetter(basicDetails?.requirement?.home_requirements?.gender_pref) : "NA"}</p>*/}
                    {/*                    </>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <>*/}
                    {/*                        <h3>Visit Patient By</h3>*/}
                    {/*                        <p>{CommonService.capitalizeFirstLetter(basicDetails?.requirement.home_requirements?.visit_patient_by) || "NA"}</p>*/}
                    {/*                    </>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                </div>*/}
                    {/*            </div>*/}


                    {/*            <div className="shift-details">*/}
                    {/*                <div>*/}
                    {/*                    <h3>Shift Requirement Details</h3>*/}
                    {/*                    <p className="summary">{basicDetails?.shift_details}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className={'d-flex mrg-top-10 mrg-bottom-20'}>*/}
                    {/*                {basicDetails?.documents && basicDetails?.documents?.map((file: any, index: number) => {*/}
                    {/*                    return (*/}
                    {/*                        <>*/}
                    {/*                            <div*/}
                    {/*                                className={'d-flex justify-content-space-between file-holder-wrapper mrg-right-10 align-items-center '}>*/}
                    {/*                                <div className={'d-flex'}>*/}
                    {/*                                    <div className={'mrg-right-10'}>*/}
                    {/*                                        <img src={ImageConfig.FileViewIcon} alt=""/>*/}
                    {/*                                    </div>*/}
                    {/*                                    <div className={"underline-text"} onClick={() => {*/}
                    {/*                                        window.open(file);*/}
                    {/*                                    }}>*/}
                    {/*                                        document {index + 1}*/}
                    {/*                                    </div>*/}
                    {/*                                </div>*/}
                    {/*                                /!*<div className={'cursor-pointer'} onClick={() => {*!/*/}
                    {/*                                /!*    removeFile(index);*!/*/}
                    {/*                                /!*}}>*!/*/}
                    {/*                                /!*    <DeleteIcon/>*!/*/}
                    {/*                                /!*</div>*!/*/}
                    {/*                            </div>*/}
                    {/*                        </>*/}
                    {/*                    );*/}
                    {/*                })}*/}
                    {/*            </div>*/}
                    {/*        </>*/}
                    {/*    }*/}
                    {/*    {basicDetails?.requirement?.type !== "home" && basicDetails?.requirement?.type !== "contract" &&*/}
                    {/*        <>*/}
                    {/*            <div className="mrg-top-10">*/}
                    {/*                <div className="d-flex shift-name-requested pdd-top-10">*/}
                    {/*                    <div className="shift-name-action-container d-flex">*/}
                    {/*                        <h2>Shift Details</h2>*/}
                    {/*                        <h4 className="shift-detail-edit" onClick={openEditShift}>*/}
                    {/*                            (Edit)*/}
                    {/*                        </h4>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="d-flex requested-on-wrapper">*/}
                    {/*                        <h3>Created On:</h3>*/}
                    {/*                        <p className="mrg-left-10">{moment(basicDetails?.created_at).format("MM-DD-YYYY")}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <p>{basicDetails?.title}</p>*/}
                    {/*                <div className="d-flex shift-details">*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Required On</h3>*/}
                    {/*                        <p>{shift_date}</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Time</h3>*/}
                    {/*                        <p>*/}
                    {/*                            /!*{start_time} &nbsp;-&nbsp;{end_time}*!/*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Shift Type</h3>*/}
                    {/*                        <p>{basicDetails?.shift_type}</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Warning Zone</h3>*/}
                    {/*                        <p>{basicDetails?.warning_type}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="d-flex shift-details">*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>HCP Differential Rate</h3>*/}
                    {/*                        <p>{basicDetails?.payments?.differential ? basicDetails?.payments?.differential : "NA"}</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Rush Rate</h3>*/}
                    {/*                        <p>{basicDetails?.requirement?.rush_rate ? basicDetails?.requirement?.rush_rate : "NA"}</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Weekend Rate</h3>*/}
                    {/*                        <p>{basicDetails?.requirement?.weekend_rate ? basicDetails?.requirement?.weekend_rate : "NA"}</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>NOC Diff</h3>*/}
                    {/*                        <p>{basicDetails?.requirement?.noc_diff ? basicDetails?.requirement?.noc_diff : "NA"}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*                <div className="d-flex shift-details">*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>PM Diff</h3>*/}
                    {/*                        <p>{basicDetails?.requirement?.pm_diff ? basicDetails?.requirement?.pm_diff : "NA"}</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Show Shift Rate in Mobile ?</h3>*/}
                    {/*                        <p>*/}
                    {/*                            {basicDetails?.requirement?.is_shift_rate ? "Yes" : "No"}*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Shift Rate</h3>*/}
                    {/*                        <p>{basicDetails?.requirement?.shift_rate ? basicDetails?.requirement?.shift_rate : "NA"}</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Hazard Rate</h3>*/}
                    {/*                        <p>*/}
                    {/*                            {basicDetails?.hasOwnProperty("hazard_rate")*/}
                    {/*                                ? basicDetails?.hazard_rate*/}
                    {/*                                    ? basicDetails?.hazard_rate*/}
                    {/*                                    : "NA"*/}
                    {/*                                : "NA"}*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*                <div className="d-flex shift-details">*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Warning Zone</h3>*/}
                    {/*                        <p>{basicDetails?.hasOwnProperty("warning_type") ? basicDetails?.warning_type : "NA"}</p>*/}
                    {/*                    </div>*/}

                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>HCP Pay Date</h3>*/}
                    {/*                        <p>{basicDetails?.hasOwnProperty("hcp_pay_date") ? (basicDetails?.hcp_pay_date ? moment(basicDetails?.hcp_pay_date).format('MM-DD-YYYY') : "NA") : "NA"}</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>HCP Speciality</h3>*/}
                    {/*                        <p>{basicDetails?.hasOwnProperty("hcp_speciality") ? (basicDetails?.hcp_speciality ?? "NA") : "NA"}</p>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="flex-1">*/}
                    {/*                        <h3>Salary Credit Day</h3>*/}
                    {/*                        <p>{basicDetails?.hasOwnProperty("hcp_details") ? (basicDetails?.hcp_details?.contract_details?.salary_credit ?? "NA") : "NA"}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}


                    {/*            </div>*/}
                    {/*        </>*/}
                    {/*    }*/}
                    {/*    {basicDetails?.requirement?.type === "contract" &&*/}
                    {/*        <>*/}
                    {/*            <div className="d-flex shift-name-requested">*/}

                    {/*                <div className="shift-name-action-container d-flex">*/}
                    {/*                    <h2>Shift Details</h2>*/}
                    {/*                    /!*<h4 className="shift-detail-edit" onClick={openEditShift}>(Edit)</h4>*!/*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <p>{basicDetails?.title}</p>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Required On</h3>*/}
                    {/*                    <p>{CommonService.getUtcDate(basicDetails?.shift_date)}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Shift Duration</h3>*/}
                    {/*                    <p>*/}
                    {/*                        {basicDetails?.requirement?.contract_requirements?.shift_duration || "0"}h*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Hcp Type</h3>*/}
                    {/*                    <p>{basicDetails?.hcp_type || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Speciality</h3>*/}
                    {/*                    <p>{basicDetails?.hcp_speciality || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Shift Type</h3>*/}
                    {/*                    <p>{basicDetails?.shift_type || "NA"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Days Per Week</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.days_per_week || "NA"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Type</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.contract_type || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Open Positions</h3>*/}
                    {/*                    <p>{basicDetails?.hcp_count || 0}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Shift Rate</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.shift_rate ? basicDetails?.requirement?.shift_rate : "NA"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Weekly Earnings</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.weekly_earning || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Travel Per Diems</h3>*/}
                    {/*                    <p>*/}
                    {/*                        {Object.entries(basicDetails?.requirement?.contract_requirements?.travel_per_diems)?.map(([key, value]) => `${key}`)}*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}

                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Travel Per Diem Rate</h3>*/}
                    {/*                    <p>*/}
                    {/*                        {Object.entries(basicDetails?.requirement?.contract_requirements?.travel_per_diems)?.map(([key, value]) => `${value}`)}*/}
                    {/*                    </p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}


                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>State License</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.state_license || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Certifications</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.certifications || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Speciality Experience</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.speciality_experience || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Skills Assessment</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.skills_assessment || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>References</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.references || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Additional Skill</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.additional_skill || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Additional Confirmations</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.additional_confirmations || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="d-flex shift-details">*/}
                    {/*                <div className="flex-1">*/}
                    {/*                    <h3>Travel Contract Limitations</h3>*/}
                    {/*                    <p>{basicDetails?.requirement?.contract_requirements?.travel_contract_limitations || "-"}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="shift-details">*/}
                    {/*                <div>*/}
                    {/*                    <h3>Shift Requirement Details</h3>*/}
                    {/*                    <p className="summary">{basicDetails?.shift_details}</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </>}*/}

                    {/*</div>*/}


                    <div className="mrg-top-10 d-flex custom-border pdd-top-10">
                        <h2>Facility Confirmation</h2>
                        <Checkbox checked={isFacilityConfirm} onChange={handleFacilityConfirmation}/>
                    </div>

                    <Formik initialValues={paymentInitialState} validationSchema={paymentValidation} onSubmit={onAdd}>
                        {({isSubmitting, handleSubmit, isValid, resetForm, setFieldValue, values}) => (
                            <Form id="add-facility-payment-form" className={"payment-name form-holder"}>
                                <div className="mrg-top-10 custom-border pdd-top-10">
                                    <h2>Facility Billed Details</h2>
                                    <div className="input-container">
                                        <Field
                                            required
                                            value={values.facility_billed_details.is_billed}
                                            onChange={(e: any) => {
                                                if (e.target.value === false) {
                                                    setFieldValue("facility_billed_details.invoice_date", null);
                                                }
                                                setIsHCPBilled(e.target.value);
                                                setFieldValue("facility_billed_details.is_billed", e.target.value);
                                            }}
                                            SelectProps={showDropDownBelowField}
                                            select
                                            variant="outlined"
                                            fullWidth
                                            component={TextField}
                                            name="facility_billed_details.is_billed"
                                            id="input_is_billed"
                                            label="Billed/Invoiced"
                                        >
                                            <MenuItem value="">Select </MenuItem>
                                            {hcpPaidOptions.map((item: any, index) => {
                                                return (
                                                    <MenuItem value={item.value} key={index} id={"is_billed"}>
                                                        {item.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Field>

                                        {isHCPBilled && (
                                            <Field
                                                required
                                                value={values.facility_billed_details.invoice_date}
                                                id="input_invoice_date"
                                                variant="outlined"
                                                format="MM/dd/yyyy"
                                                component={TextField}
                                                placeholder="MM/DD/YYYY"
                                                type="date"
                                                fullWidth
                                                autoComplete="off"
                                                InputLabelProps={{shrink: true}}
                                                label="Invoice Date"
                                                name="facility_billed_details.invoice_date"
                                                onChange={(e: any) => setFieldValue("facility_billed_details.invoice_date", e.target.value)}
                                            />
                                        )}
                                    </div>

                                    <Field
                                        value={values.facility_billed_details.comments}
                                        onChange={(e: any) => {
                                            setFieldValue("facility_billed_details.comments", e.target.value);
                                        }}
                                        multiline
                                        variant="outlined"
                                        fullWidth
                                        component={TextField}
                                        name="facility_billed_details.comments"
                                        label="Comments"
                                        id="input_facility_comments"
                                        minRows={3}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>

                    <div className="custom-border mrg-top-40 pdd-top-10">
                        <h2>Remarks</h2>
                        <TextField
                            value={remarks}
                            onChange={(e: any) => {
                                setRemarks(e.target.value);
                            }}
                            multiline
                            variant="outlined"
                            fullWidth
                            name="remarks"
                            id="input_facility_remarks"
                            minRows={3}
                        />
                    </div>

                    <div className="mrg-top-10 custom-border pdd-top-10">
                        <div className="mrg-top-20">
                            {attachmentsList?.length > 0 ? (
                                <>
                                    <h3>Attachment:</h3>
                                    <div className="d-flex" style={{gap: "50px"}}>
                                        {attachmentsList?.map((item: any, index: any) => {
                                            return (
                                                <div className="attachments">
                                                    <div>
                                                        <p className="">{item?.attachment_type}</p>
                                                        <Tooltip title="Preview Timesheet Form">
                                                            {
                                                                <InsertDriveFileIcon
                                                                    color={"primary"}
                                                                    className="file-icon"
                                                                    onClick={() => previewFile(index, "api")}
                                                                    style={{cursor: "pointer"}}
                                                                />
                                                            }
                                                        </Tooltip>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Tooltip title="Download Timesheet Form">
                                                            <p onClick={() => previewFile(index, "api")}
                                                               className="file-actions">
                                                                Preview
                                                            </p>
                                                        </Tooltip>
                                                        <Tooltip title="Download Timesheet Form">
                                                            <div className="file-actions mrg-left-10" onClick={() => {
                                                                CommonService.handleDownloadAnyAttachment(item?.file_key)
                                                            }}>
                                                                Download
                                                            </div>
                                                        </Tooltip>
                                                        <Tooltip title="Download Timesheet Form">
															<span className="file-actions mrg-left-10"
                                                                  onClick={handleDeleteTimesheet}>
																Delete
															</span>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h3 className="mrg-top-0">Attachment:</h3>
                                    <div className="d-flex" style={{gap: "50px"}}>
                                        {required_attachments?.map((item: any, index: any) => {
                                            if (item.index !== -1) {
                                                return (
                                                    <>
                                                        <div className="attachments">
                                                            <div className="custom_file mrg-top-0">
                                                                <h3 className="mrg-top-20 mrg-bottom-0 file_name file_attachment_title">
                                                                    {" "}
                                                                    {fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload?.file_type}
                                                                </h3>
                                                                <div className="mrg-top-15">
                                                                    <InsertDriveFileIcon color={"primary"}
                                                                                         className="file-icon"/>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex file_actions">
                                                                <Tooltip
                                                                    title={`View ${
                                                                        fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload?.file_type
                                                                    }`}
                                                                >
                                                                    <p
                                                                        style={{cursor: "pointer", width: "50px"}}
                                                                        className={"delete-cdhp mrg-top-0"}
                                                                        onClick={() => previewFile(index, "local")}
                                                                    >
                                                                        View
                                                                    </p>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    title={`Delete ${
                                                                        fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload?.file_type
                                                                    }`}
                                                                >
                                                                    <p
                                                                        style={{cursor: "pointer", width: "50px"}}
                                                                        className={"delete-cdhp mrg-top-0"}
                                                                        onClick={() => deleteFile(index)}
                                                                    >
                                                                        Delete
                                                                    </p>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <div className="attachments">
                                                        <div className="">
                                                            <h3 className="attachement_name file_attachment_title">{item?.name}</h3>
                                                            <Tooltip title={`Upload ${item?.name}`}>
                                                                <div>
                                                                    <FileDropZoneComponent
                                                                        OnFileSelected={(item) => OnFileSelected(item, index)}
                                                                        allowedTypes={".pdf"}
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="action-btn-grp">
                        <Button
                            size="large"
                            className={isSubmitting || isTimeSheetBeingUpdated ? "has-loading-spinner" : ""}
                            disabled={isSubmitting || isTimeSheetBeingUpdated}
                            form="add-facility-payment-form"
                            variant="contained"
                            color="primary"
                            type="submit"
                            id="btn_submit"
                        >
                            {isSubmitting ? "Saving" : "Save"}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default CancelledShiftsViewScreen;
