import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import StarIcon from "@material-ui/icons/Star";

export const designationNames = [
    {value: "DON", label: "Director Of Nursing (DON)"},
    {value: "DSD", label: "Director Of Staffing development (DSD)"},
    {value: "FA", label: "Facility Administrator (FA)"},
    {value: "AP", label: "Accounts Payable (AP)"},
    {value: "SC", label: "Staffing Coordinator (SC)"},
];

export const salaryCredit = [
    {value: "weekly", label: "Weekly"},
    {value: "bi_weekly", label: "Bi-Weekly"},
];

export const hcpPaymentModeOptions = [
    {value: "direct_deposit", label: "GUSTO - Direct Deposit"},
    {value: "checkrpay", label: "CHECKR PAY"},
    {value: "paycheck", label: "MAIL - Paycheck"},
    {value: "none", label: "None"}
];

export const otHours = [
    {value: 40, label: "40 Hrs/Week"},
    {value: 8, label: "8 Hrs/Day"},
];

export const shiftType = [
    {
        value: "AM",
        label: "AM",
    },
    {
        value: "PM",
        label: "PM",
    },
    {
        value: "NOC",
        label: "NOC",
    },

    {
        value: "AM-12",
        label: "AM-12",
    },

    {
        value: "PM-12",
        label: "PM-12",
    },
    {
        value: 'AM/PM-Double',
        label: 'AM/PM-Double'
    },
    {
        value: 'PM/NOC-Double',
        label: 'PM/NOC-Double'
    },
    {
        value: 'NOC/AM-Double',
        label: 'NOC/AM-Double'
    },
    {
        value: 'AM-13',
        label: 'AM-13'
    },
    {
        value: 'PM-13',
        label: 'PM-13'
    }
];

export const home_shift_type: any = [
    {
        code: 'Evaluation (RN/OT/PT/ST)',
        title: 'Evaluation (RN/OT/PT/ST)'
    },
    {
        code: 'Start of Care (RN/OT/PT/ST)',
        title: 'Start of Care (RN/OT/PT/ST)'
    },
    {
        code: "Routine visit (RN)",
        title: "Routine visit (RN)"
    },
    {
        code: "Routine visit (LVN)",
        title: "Routine visit (LVN)"
    },
    {
        code: "Routine visit (OT/PT/ST)",
        title: "Routine visit (OT/PT/ST)"
    },
    {
        code: "Routine visit (OTA/PTA/STA)",
        title: "Routine visit (OTA/PTA/STA)"
    },
    {
        code: "Discharge",
        title: "Discharge"
    },
    {
        code: "Recertification",
        title: "Recertification"
    },
    {
        code: "Resumption of Care",
        title: "Resumption of Care"
    },
    {
        code: "Routine visit (CNA)",
        title: "Routine visit (CNA)"
    },
    {
        code: "Routine visit (CareGiver/CHHA)",
        title: "Routine visit (CareGiver/CHHA)"
    }

];

export const patientsGender = [
    {title: "Male", code: "male"},
    {title: "Female", code: "female"},
    {title: "Neutral", code: "neutral"},
];


export const genderTypes = [
    {label: "Male", value: "male"},
    {label: "Female", value: "female"},
    {label: "Other", value: "other"},
];

export const warningZone = [
    {value: "green", label: "Green"},
    {value: "yellow", label: "Yellow"},
    {value: "red", label: "Red"},
];

export const cardMenuActions = [
    {
        icon: <EditIcon/>,
        label: "Edit",
    },
    {
        icon: <DeleteIcon/>,
        label: "Delete",
    },
    {
        icon: <StarIcon/>,
        label: "Star",
    },
];

export const calenderMode = [
    {
        value: "multiple",
        label: "Multiple Dates",
    },
    {
        value: "range",
        label: "Date Range",
    },
];

export const DateRangeContract = [
    {
        value: "range",
        label: "Date Range",
    },
];

export const acknowledgement = [
    {value: "1", label: "Yes"},
    {value: "0", label: "No"},
];

export const boolAcknowledge = [
    {value: "true", label: "Yes"},
    {value: "false", label: "No"},
    {value: "", label: "None"},
];


export const genericAcknowledgement = [
    {value: "true", label: "Yes"},
    {value: "false", label: "No"},
];

export const timesheet = [
    {value: "", label: "All"},
    {value: true, label: "Yes"},
    {value: false, label: "No"},
];

export const requestedByMenu = [
    {value: "", label: "All"},
    {value: "facility", label: "Facility"},
    {value: "hcp", label: "HCP"},
]
export const requirementTypeList = [
    {value: "facility", label: "Per Diem"},
    {value: "home", label: "Per Job / Visit"},
    {value: "contract", label: "Contract / Travel"},
]
export const facilityConfirmation = [
    {value: "", label: "All"},
    {value: true, label: "Yes"},
    {value: false, label: "No"},
];

export const pendingConcern = [
    {value: "", label: "All"},
    {value: true, label: "Yes"},
    {value: false, label: "No"},
];

export const NurseCancelled = [
    {value: "1", label: "1 hours Notice"},
    {value: "4", label: "4 hours Notice"},
    {value: "8", label: "8 hours Notice"},
    {value: "24", label: "24 hours Notice"},
    {value: "48", label: "48 hours Notice"},
    {value: "72", label: "72 hours"},
    {value: "resignation", label: "Resignation"}
];
export const NoShow = [
    {value: "1-day", label: "1 day"},
    {value: "smultiple(2-30)", label: "Multiple - no. of days (2-30)"},
];
export const FacilityCancelled = [
    {value: "72", label: "Within 72 hours"},
    {value: "no-of-days(2-30)", label: "no. of days (2-30)"},
    {value: "low-census", label: "Low Census"},
    {value: "ncns-1-day", label: "NCNS 1 day"},
    {value: "ncns-multiple(2-30)", label: "NCNS Multiple - no. of days (2-30)"},
    {value: "hcp-issue", label: "HCP Issue"},
];
export const VitawerksCancelled = [
    {value: "snf", label: "SNF Issues"},
    {value: "hcp-issue-dnr", label: "HCP Issues(DNR)"},
    {value: "hcp-issues-all-snf", label: "HCP Issues(DNS All SNF)"},
];

export const othersCancelled = [
    {value: "duplicate-entry", label: "Duplicate Entries"},
    {value: "incorrect-information", label: "Incorrect Information"},
    {value: "testing-training", label: "Testing/Training"},
    {value: "no-timesheet", label: "No Timesheet"},
];
export const HcpPaymentMode = [
    {value: "", label: "All"},
    {value: "paycheck", label: "MAIL - Paycheck"},
    {value: "direct_deposit", label: "GUSTO - Direct Deposit"},
    {value: "checkrpay", label: "CHECKR PAY"},
];


export const isHCPPaid = [
    {value: true, label: "Yes"},
    {value: false, label: "No"},
];

export const shiftTypePreference = [
    {
        value: "AM",
        label: "AM",
    },
    {
        value: "PM",
        label: "PM",
    },
    {
        value: "NOC",
        label: "NOC",
    },
    {
        value: "AM,PM",
        label: "AM and PM",
    },
    {
        value: "PM,NOC",
        label: "PM and NOC",
    },
    {
        value: "NOC,AM",
        label: "NOC and AM",
    },
];

export const travelDistancePreference = [
    {value: "<20", label: "<20 miles"},

    {value: "20-40", label: "20-40 miles"},

    {value: "40-60", label: "40-60 miles"},

    {value: ">60", label: ">60 miles"},
];

export const notificationPreference = [
    {value: "sms", label: "SMS"},
    {value: "email", label: "Email"},
    {value: "push_notification", label: "In-Application"},
];

export const moreImportant = [
    {
        value: "money",
        label: "Money",
    },
    {
        value: "location",
        label: "Location",
    },
    {value: "", label: "None"},
];

export const covidPreference = [
    {
        value: "covid",
        label: "Covid",
    },
    {
        value: "non_covid",
        label: "Non Covid",
    },

    {
        value: "both",
        label: "Both",
    },
    {value: "", label: "None"},
];

export const vaccine = [
    {
        value: "full",
        label: "Fully Vaccinated",
    },
    {
        value: "half",
        label: "1st Dose",
    },
    {
        value: "exempted",
        label: "Exempted",
    },
];

export const contactType = [
    {
        value: "email",
        label: "Email",
    },
    {
        value: "text_message",
        label: "Text Message",
    },
    {
        value: "voicemail",
        label: "Voicemail",
    },
    {
        value: "chat",
        label: "Chat",
    },
];

export const gustoType = [
    {
        value: "direct_deposit",
        label: "Direct Deposit",
    },
    {
        value: "paycheck",
        label: "Paycheck",
    },
    {value: "", label: "None"},
];

export const AllShiftStatusList = [
    {name: "In Progress", code: "in_progress"},
    {name: "Cancelled", code: "cancelled"},
    {name: "Complete", code: "complete"},
    {name: "Closed", code: "closed"},
    {name: "Approved", code: "pending"},
];

export const OpenShiftsStatusList = [
    {name: "Open", code: "open"},
    {name: "Cancelled", code: "cancelled"},
    {name: "Unfilled", code: "unfilled"},
    {code: "PartiallyOpen", name: "Partially Open"},
    {code: "filled", name: "Filled"},
];

export const pendingShiftsStatusList = [
    {name: "Pending", code: "pending"},
    // {name: "Awaiting", code: "awaiting"},
    {name: "Cancelled", code: "cancelled"},
    {name: "Rejected", code: "rejected"},
    {name: "Approved", code: "approved"},
];
export const awaitingShiftsStatusList = [
    // {name: "Pending", code: "pending"},
    {name: "Awaiting", code: "awaiting"},
    {name: "Cancelled", code: "cancelled"},
    {name: "Rejected", code: "rejected"},
    {name: "Approved", code: "approved"},
];


export const approvedListStatus = [
    {name: "Active", code: true},
    {name: "Inactive", code: false},
];
export const onboardedListStatus = [
    {name: "Pending", code: "pending"},
    {name: "Rejected", code: "rejected"},
    {name: "All", code: "all"},
];

export const americanTimeZone = [
    {value: 720, label: "(UTC−12:00) Baker Island and Howland Island "},
    {value: 660, label: "(UTC−11:00) (ST) American Samoa, Jarvis Island, Kingman Reef, Midway Atoll and Palmyra Atoll"},
    {value: 600, label: "(UTC−10:00) (HT) Hawaii, most of the Aleutian Islands, and Johnston Atoll"},
    {value: 540, label: "(UTC−09:00) (AKT) Most of the State of Alaska "},
    {
        value: 480,
        label: "(UTC−08:00) (PT) Pacific Time zone: the Pacific coast states, the Idaho panhandle and most of Nevada and Oregon "
    },
    {
        value: 420,
        label: "(UTC−07:00) (MT) Mountain Time zone: most of Idaho, part of Oregon, and the Mountain states plus western parts of some adjacent states "
    },
    {
        value: 360,
        label: "(UTC-06:00) (CT) Central Time zone: a large area spanning from the Gulf Coast to the Great Lakes "
    },
    {
        value: 300,
        label: "(UTC−05:00) (ET) Eastern Time zone: roughly a triangle covering all the states from the Great Lakes down to Florida and east to the Atlantic coast"
    },
    {value: 240, label: "((UTC−04:00) AST) Puerto Rico, the U.S. Virgin Islands  "},
    {value: -600, label: "(UTC+10:00) (ChT) Guam and the Northern Mariana Islands "},
    {value: -720, label: "(UTC+12:00) Wake Island "},
];

export const roles = [
    {value: "account_manager", label: "AM"},
    {value: "nurse_champion", label: "NC"},
    {value: "hr", label: "HR"},
    {value: 'operational_manager', label: "OM"},
    {value: 'finance', label: "Finance"},
    {value: 'super_admin', label: "Admin"},
];


export const timeTypesForFilters = [
    {
        code: "AM",
        title: "AM",
    },
    {
        code: "PM",
        title: "PM",
    },
    {
        code: "NOC",
        title: "NOC",
    },

    {
        code: "AM-12",
        title: "AM-12",
    },

    {
        code: "PM-12",
        title: "PM-12",
    },
    {
        code: 'AM/PM-Double',
        title: 'AM/PM-Double'
    },
    {
        code: 'PM/NOC-Double',
        title: 'PM/NOC-Double'
    },
    {
        code: 'NOC/AM-Double',
        title: 'NOC/AM-Double'
    },
    {
        code: 'AM-13',
        title: 'AM-13'
    },
    {
        code: 'PM-13',
        title: 'PM-13'
    },
    {
        code: 'Evaluation (RN/OT/PT/ST)',
        title: 'Evaluation (RN/OT/PT/ST)'
    },
    {
        code: 'Start of Care (RN/OT/PT/ST)',
        title: 'Start of Care (RN/OT/PT/ST)'
    },
    {
        code: "Routine visit (RN)",
        title: "Routine visit (RN)"
    },
    {
        code: "Routine visit (LVN)",
        title: "Routine visit (LVN)"
    },
    {
        code: "Routine visit (OT/PT/ST)",
        title: "Routine visit (OT/PT/ST)"
    },
    {
        code: "Routine visit (OTA/PTA/STA)",
        title: "Routine visit (OTA/PTA/STA)"
    },
    {
        code: "Routine visit (CNA)",
        title: "Routine visit (CNA)"
    },
    {
        code: "Routine visit (CareGiver/CHHA)",
        title: "Routine visit (CareGiver/CHHA)"
    },
    {
        code: "Discharge",
        title: "Discharge"
    },
    {
        code: "Recertification",
        title: "Recertification"
    },
    {
        code: "Resumption of Care",
        title: "Resumption of Care"
    },
    {
        code: "days",
        title: "days",
    },
    {
        code: "nights",
        title: "nights",
    },
    {
        code: "flexible",
        title: "flexible",
    },

];


export const salaryCreditTypesForFilters = [
    {code: "weekly", name: "Weekly"},
    {code: "bi_weekly", name: "Bi-Weekly"},
    {code: "", name: "Salary Credit Day NA"},
];


export const visitPatientBy = [
    {
        title: "Date",
        code: "date",
    },
    {
        title: "Time",
        code: "time",
    },
]

export const languagesList = [
    {
        code: "English",
        title: "English",
    },
    {
        code: "Spanish",
        title: "Spanish",
    },
    {
        code: "Hindi",
        title: "Hindi",
    },
    {
        code: "Mandarin",
        title: "Mandarin",
    },
    {
        code: "Russian",
        title: "Russian",
    },
    {
        code: "Other",
        title: "Other",
    },

]


export const weekDaysList = [
    {
        code: "1",
        title: "1",
    }, {
        code: "2",
        title: "2",
    },
    {
        code: "3",
        title: "3",
    },
    {
        code: "4",
        title: "4",
    },
    {
        code: "5",
        title: "5",
    },
    {
        code: "6",
        title: "6",
    },
    {
        code: "7",
        title: "7",
    },
]


export const contractMealsType = [
    {
        code: "meals",
        title: "Meals",
    }, {
        code: "housing",
        title: "Housing",
    },
    {
        code: "other",
        title: "Other",
    },
]

export const contractShiftType = [
    {
        code: "contract",
        title: "Contract",
    },
    {
        code: "travel",
        title: "Travel",
    },
    {
        code: "permanent",
        title: "Permanent",
    },
]

export const shiftDurationList = [
    {
        code: "8",
        title: "8 Hours",
    },
    {
        code: "10",
        title: "10 Hours",
    },
    {
        code: "12",
        title: "12 Hours",
    },
]

export const contactTypeList = [
    {
        code: "days",
        title: "Days",
    }, {
        code: "nights",
        title: "Nights",
    }, {
        code: "flexible",
        title: "Flexible",
    }
]
