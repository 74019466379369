import {Button, Checkbox, Tooltip} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {SearchRounded} from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    TsDataListOptions,
    TsDataListState,
    TsDataListWrapperClass,
} from "../../../../classes/ts-data-list-wrapper.class";
import DialogComponent from "../../../../components/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import NoDataCardComponent from "../../../../components/NoDataCardComponent";
import {useLocalStorage} from "../../../../components/useLocalStorage";
import {ENV} from "../../../../constants";
import {CssTextField} from "../../../../constants/data/styles";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import ShiftFilter from "../../filters/ShiftFilter";
import RejectShiftComponent from "../../rejectShift/RejectShiftComponent";
import AddPaymentDetailsComponent from "./addPaymentDetails/AddPaymentDetailsComponent";
import "./ClosedShiftsListScreen.scss";
import PendingConcernConfirmationBox from "./pending-concern-confirm-closed/PendingConcernConfirmationBox";
import {useSelector} from "react-redux";
import {StateParams} from "../../../../store/reducers";


const [firstDayOfMonth, lastDayOfMonth] = CommonService.getFirstDayAndLastDayUsingDate();


const ClosedShiftsScreen = () => {
    const [list, setList] = useState<TsDataListState | null>(null);
    // const [hcpTypes, setHcpTypes] = useState<any | null>(null);
    const {hcpTypes} = useSelector((state: StateParams) => state.meta);
    const [facilityList, setFacilityList] = useState<any | null>(null);
    // const [regions, setRegions] = useState<any>([]);
    const {regions} = useSelector((state: StateParams) => state.meta);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [selectedRegion, setSelectedRegion] = useLocalStorage<any[]>("selectedRegion", []);
    const [selectedHcps, setSelectedHcps] = useLocalStorage<any[]>("selectedHcps", []);
    const [selectedFacilities, setSelectedFacilities] = useLocalStorage<any[]>("selectedFacilities", []);
    const [selectedTimeTypes, setSelectedTimeTypes] = useLocalStorage<any[]>("selectedTimeTypes", []);
    const [selectedSalaryCreditTypes, setSelectedSalaryCreditTypes] = useLocalStorage<any[]>("selectedSalaryCreditTypes", []);
    const [isHcpPayment, setIsHcpPayment] = useLocalStorage<any>("isHcpPayment", "");
    const [dateRange, setDateRange] = useLocalStorage<any[]>("dateRange", [firstDayOfMonth, lastDayOfMonth]);
    const [paymentDateRange, setPaymentDateRange] = useLocalStorage<any[]>("paymentDateRange", [null, null]);
    const [selectedApprovedHcps, setSelectedApprovedHcps] = useLocalStorage<any[]>("selectedApprovedHcps", []);
    const [isFacilityListLoading, setIsFacilityListLoading] = useState<boolean>(false);
    const [pageSizeIndex, setPageSizeIndex] = useLocalStorage<any>("shiftClosedPageSizeIndex", 10);
    const [selectedShifts, setSelectedShifts] = useState<any>([]);
    const [isAllselected, setIsAllSelected] = useState<boolean>(false);
    const [selectedCount, setSelectedCount] = useState<any>(-1);
    const [isAddPaymentDetailsOpen, setIsAddPaymentDetailsOpen] = useState<boolean>(false);
    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [isHcpSentHome, setIsHcpSentHome] = useLocalStorage<any>("isHcpSentHome", "");
    const [shiftSearch, setShiftSearch] = useState<string>("");
    const [hcpPaymentModeValueForClosedShift, setHcpPaymentModeValueForClosedShifts] = useLocalStorage<any>("hcpPaymentModeValueForClosedShift", '');
    const [isPendingConcernOpen, setIsPendingConcernOpen] = useState<boolean>(false);
    const [isPendingConcern, setIsPendingConcern] = useLocalStorage<any>("isPendingConcernForClosedShift", "");
    const [isTheSelectedShiftPendingConcern, setIsTheSelectedShiftPendingConcern] = useState<any>(false);

    const [shiftRequirementType, setRequirementType] = useLocalStorage<any>("closed-shift-requirement-type", "facility");
    // const getHcpTypes = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "meta/hcp-types")
    //         .then((resp) => {
    //             setHcpTypes(resp.data || []);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    const handleDownload = useCallback(() => {
        setIsDownloading(true);
        let payload: any = {};

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }
        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }
        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (isPendingConcern?.value !== "") {
            payload.is_pending_concern = isPendingConcern?.value;
        }

        payload.status = ['closed'];

        if (isHcpPayment?.value !== "") {
            payload.is_hcp_paid = isHcpPayment.value;
        }

        if (hcpPaymentModeValueForClosedShift?.value !== "") {
            payload.gusto_type = hcpPaymentModeValueForClosedShift?.value;
        }

        if (isHcpSentHome.value !== "") {
            payload.is_sent_home = isHcpSentHome?.value;
        }

        if (shiftSearch !== "") {
            payload.search = shiftSearch;
        }

        if (paymentDateRange[0] || paymentDateRange[1]) {
            let startDate = moment(paymentDateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(paymentDateRange[1]).format("YYYY-MM-DD");

            if (!paymentDateRange[1]) {
                payload.hcp_pay_start_date = startDate;
                payload.hcp_pay_end_date = startDate;
            } else {
                payload.hcp_pay_start_date = startDate;
                payload.hcp_pay_end_date = endDate;
            }
        }

        if (selectedSalaryCreditTypes.length > 0) {
            payload.salary_credit = selectedSalaryCreditTypes.map(item => item.code);
        }


        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");
            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            let today = moment(new Date()).format("YYYY-MM-DD");
            payload.new_shifts = today;
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        ApiService.post(ENV.API_URL + "shift/download", payload)
            .then((res) => {
                if (res?.data) {
                    const link = document.createElement("a");
                    link?.setAttribute("href", res?.data);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloading(false);
                } else {
                    CommonService.showToast(res?.msg || "No Data to Download", "info");
                    setIsDownloading(false);
                }
            })
            .catch((err) => {
                setIsDownloading(false);
                CommonService.showToast(err?.msg || "Error", "error");
            });
    }, [selectedRegion, dateRange, selectedFacilities, selectedHcps, selectedSalaryCreditTypes, selectedApprovedHcps, selectedTimeTypes, paymentDateRange, isHcpSentHome, isHcpPayment, shiftSearch, hcpPaymentModeValueForClosedShift, isPendingConcern, shiftRequirementType]);
    //
    // const getRegions = useCallback(() => {
    //     CommonService._api
    //         .get(ENV.API_URL + "meta/hcp-regions")
    //         .then((resp) => {
    //             setRegions(resp.data || []);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, []);

    const getFacilityData = useCallback(() => {
        setIsFacilityListLoading(true);
        let payload: any = {};
        if (selectedRegion.length > 0) {
            const selectedRegions = selectedRegion;
            payload.regions = selectedRegions;
        }
        ApiService.post(ENV.API_URL + "facility/lite", payload)
            .then((res) => {
                setFacilityList(res?.data || []);
                setIsFacilityListLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFacilityListLoading(false);
            });
    }, [selectedRegion]);

    useEffect(() => getFacilityData(), [selectedRegion, getFacilityData]);

    const init = useCallback(() => {
        let url = "shift/closed";
        let payload: any = {};

        payload.status = "closed";

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }

        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (isPendingConcern?.label !== "All") {
            payload.is_pending_concern = isPendingConcern?.value;
        }

        if (hcpPaymentModeValueForClosedShift?.value !== "") {
            payload.gusto_type = hcpPaymentModeValueForClosedShift?.value;
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }

        if (isHcpPayment.value !== "") {
            payload.is_hcp_paid = isHcpPayment.value;
        }

        if (isHcpSentHome.value !== "") {
            payload.is_sent_home = isHcpSentHome?.value;
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            let today = moment(new Date()).format("YYYY-MM-DD");
            payload.new_shifts = today;
        }


        if (paymentDateRange[0] || paymentDateRange[1]) {
            let startDate = moment(paymentDateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(paymentDateRange[1]).format("YYYY-MM-DD");

            if (!paymentDateRange[1]) {
                payload.hcp_pay_start_date = startDate;
                payload.hcp_pay_end_date = startDate;
            } else {
                payload.hcp_pay_start_date = startDate;
                payload.hcp_pay_end_date = endDate;
            }
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        if (selectedSalaryCreditTypes.length > 0) {

            payload.salary_credit = selectedSalaryCreditTypes.map(item => item.code);


        }

        const options = new TsDataListOptions(
            {
                // @ts-ignore
                pagination: {
                    ...list?.table?.pagination,
                    pageSize: pageSizeIndex,
                },
                extraPayload: payload,
                webMatColumns: [
                    " ",
                    " ",
                    "Clock In", "Break In", "Break Out", "Clock Out",
                    "Facility Name",
                    "Staff Name",
                    "Payment Date",
                    "Shift Date",
                    "Staff Type",
                    "Shift Type",
                    "Staff Sent Home",
                    "Staff Paid",
                    "TimeSheet",
                    "Facility Confirmation",
                    "Actions",
                ],
                mobileMatColumns: [
                    " ",
                    " ",
                    "Clock In", "Break In", "Break Out", "Clock Out",
                    "Facility Name",
                    "Staff Name",
                    "Payment Date",
                    "Shift Date",
                    "Staff Type",
                    "Shift Type",
                    "Staff Sent Home",
                    "Staff Paid",
                    "TimeSheet",
                    "Facility Confirmation",
                    "Actions",
                ],
            },
            ENV.API_URL + url,
            setList,
            ApiService,
            "post"
        );

        let tableWrapperObj = new TsDataListWrapperClass(options);
        setList({table: tableWrapperObj});
        // eslint-disable-next-line
    }, [shiftRequirementType]);

    const getList = useCallback(() => {
        if (!list) {
            init();
            return;
        }

        let payload: any = {};

        payload.status = "closed";

        if (selectedRegion.length > 0) {
            payload.regions = selectedRegion;
        }

        if (selectedFacilities.length > 0) {
            payload.facilities = selectedFacilities.map((item: any) => item?.code);
        }

        if (selectedApprovedHcps.length > 0) {
            payload.hcp_user_ids = selectedApprovedHcps.map((item: any) => item?.code);
        }
        if (isPendingConcern?.label !== "All") {
            payload.is_pending_concern = isPendingConcern?.value;
        }

        if (hcpPaymentModeValueForClosedShift?.value !== "") {
            payload.gusto_type = hcpPaymentModeValueForClosedShift?.value;
        }

        if (selectedHcps.length > 0) {
            payload.hcp_types = selectedHcps;
        }

        if (shiftRequirementType?.value !== '') {
            payload.type = shiftRequirementType?.value;
        }

        if (isHcpPayment.value !== "") {
            payload.is_hcp_paid = isHcpPayment.value;
        }

        if (isHcpSentHome.value !== "") {
            payload.is_sent_home = isHcpSentHome?.value;
        }

        if (paymentDateRange[0] || paymentDateRange[1]) {
            let startDate = moment(paymentDateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(paymentDateRange[1]).format("YYYY-MM-DD");

            if (!paymentDateRange[1]) {
                payload.hcp_pay_start_date = startDate;
                payload.hcp_pay_end_date = startDate;
            } else {
                payload.hcp_pay_start_date = startDate;
                payload.hcp_pay_end_date = endDate;
            }
        }

        if (dateRange[0] || dateRange[1]) {
            let startDate = moment(dateRange[0]).format("YYYY-MM-DD");
            let endDate = moment(dateRange[1]).format("YYYY-MM-DD");

            if (!dateRange[1]) {
                payload.start_date = startDate;
                payload.end_date = startDate;
            } else {
                payload.start_date = startDate;
                payload.end_date = endDate;
            }
        } else {
            let today = moment(new Date()).format("YYYY-MM-DD");
            payload.new_shifts = today;
        }

        if (selectedTimeTypes.length > 0) {
            payload.shift_types = selectedTimeTypes;
        }

        if (selectedSalaryCreditTypes.length > 0) {

            payload.salary_credit = selectedSalaryCreditTypes.map(item => item.code);


        }

        list?.table?.setExtraPayload(payload);
        list?.table?.getList(1);
        // eslint-disable-next-line
    }, [selectedRegion, selectedTimeTypes, selectedSalaryCreditTypes, selectedFacilities, selectedApprovedHcps, selectedHcps, isHcpPayment, isHcpSentHome, paymentDateRange, dateRange, hcpPaymentModeValueForClosedShift, isPendingConcern, shiftRequirementType]);

    if (list?.table?.data) {
        list?.table?.data?.sort((a: any, b: any) => {
            return Date.parse(b.created_at) - Date.parse(a.created_at);
        });
    }

    const handleSelectAll = (event: any) => {
        if (event.target.checked === true) {
            let temp: any[] = [];
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                if (index !== -1 && item?.is_pending_concern === true) {
                    setIsTheSelectedShiftPendingConcern(true);
                }
                if (index === -1) {
                    temp.push(item._id);
                }
            });
            setSelectedShifts([...selectedShifts, ...temp]);
            setSelectedCount(1);
        } else {
            list?.table?.data?.forEach((item: any) => {
                let index = selectedShifts?.indexOf(item?._id);
                selectedShifts.splice(index, 1);
            });
            setIsTheSelectedShiftPendingConcern(false);
            setSelectedShifts([...selectedShifts]);
            setSelectedCount(selectedShifts?.length === 0 ? -1 : selectedShifts?.length);
        }
        setIsAllSelected(event.target.checked);
    };

    const handleSelectShifts = useCallback(
        (event: any, _id: any, is_pending_concern: any) => {
            if (event.target.checked === true) {
                if (is_pending_concern) {
                    setIsTheSelectedShiftPendingConcern(true);
                }
                setSelectedShifts([...selectedShifts, _id]);
                setSelectedCount(1);
            } else {
                let tempSelectedShifts = selectedShifts?.filter((item: any) => item !== _id);
                setSelectedShifts([...tempSelectedShifts]);
                if (tempSelectedShifts?.length === 0) {
                    setSelectedCount(-1);
                }
            }
        },
        [selectedShifts]
    );

    const isSelected = useCallback(
        (_id: any) => {
            if (selectedShifts?.indexOf(_id) !== -1) {
                return true;
            } else {
                return false;
            }
        },
        [selectedShifts]
    );

    const openPaymentDetails = useCallback(() => {
        setIsAddPaymentDetailsOpen(true);
    }, []);

    const afterConfirm = useCallback(() => {
        list?.table?.reload();
        setIsAddPaymentDetailsOpen(false);
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, [list?.table]);

    const cancelPaymentDetails = useCallback(() => {
        setIsAddPaymentDetailsOpen(false);
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const openRejectShift = useCallback(() => {
        setRejectShiftOpen(true);
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        setSelectedCount(-1);
        list?.table?.reload();
        setSelectedShifts([]);
    }, [list?.table, setSelectedCount, setSelectedShifts]);

    const clearFilterValues = () => {
        setSelectedTimeTypes([]);
        setSelectedSalaryCreditTypes([]);
        setSelectedFacilities([]);
        setSelectedApprovedHcps([]);
        setSelectedHcps([]);
        setSelectedRegion([]);
        setDateRange([firstDayOfMonth, lastDayOfMonth]);
        setPaymentDateRange([null, null]);
        setIsHcpPayment("");
        setIsHcpSentHome('');
        setHcpPaymentModeValueForClosedShifts("");
        setIsPendingConcern("");
        setRequirementType("")
    };

    const resetFilters = () => {
        clearFilterValues();
    };

    useEffect(() => {
        Communications.pageTitleSubject.next("Closed");
        Communications.pageBackButtonSubject.next(null);
    }, []);

    useEffect(() => {
        getList();
    }, [getList]);

    useEffect(() => {
        setIsAllSelected(false);
    }, [list?.table?.data]);

    useEffect(() => {
        let count = 0;
        list?.table?.data?.forEach((item: any) => {
            if (selectedShifts?.indexOf(item?._id) !== -1) {
                count++;
            }
        });
        if (list?.table?.data?.length !== 0 && count === list?.table?.data?.length) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
    }, [list?.table?.data, selectedShifts]);

    const openPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(true);
    }, []);

    const cancelMarkingPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(false);
        setSelectedCount(-1);
        setSelectedShifts([]);
    }, []);

    const confirmMarkingPendingConcern = useCallback(() => {
        setIsPendingConcernOpen(false);
        setSelectedCount(-1);
        return new Promise((resolve, reject) => {
            ApiService.patch(ENV.API_URL + "shift/markConcern", {
                shift_ids: selectedShifts
            })
                .then((res: any) => {
                    resolve(res);
                    CommonService.showToast(`${res?.message}` || "success", "success");
                    setSelectedShifts([]);
                    getList();
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, [selectedShifts, getList]);


    return (
        <div className="closed-shifts screen crud-layout pdd-30">
            <DialogComponent open={isAddPaymentDetailsOpen} cancel={cancelPaymentDetails}>
                <AddPaymentDetailsComponent
                    cancel={cancelPaymentDetails}
                    confirm={afterConfirm}
                    selectedShifts={selectedShifts}
                />
            </DialogComponent>

            <DialogComponent isEditableDialog={true} open={isPendingConcernOpen} cancel={cancelMarkingPendingConcern}>
                <PendingConcernConfirmationBox selectedShifts={selectedShifts}
                                               cancelMarkingPendingConcern={cancelMarkingPendingConcern}
                                               confirmMarkingPendingConcern={confirmMarkingPendingConcern}/>
            </DialogComponent>

            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift}>
                <RejectShiftComponent
                    isMultipleReasons={true}
                    cancel={cancelRejectShift}
                    confirm={confirmRejectShift}
                    selectedShifts={selectedShifts}
                />
            </DialogComponent>

            {list && list.table?._isDataLoading && (
                <div className="table-loading-indicator">
                    <LoaderComponent/>
                </div>
            )}

            <ShiftFilter
                isPendingConcernForClosedShift={isPendingConcern}
                setIsPendingConcernForClosedShift={setIsPendingConcern}
                hcpPaymentModeValueForClosedShift={hcpPaymentModeValueForClosedShift}
                setHcpPaymentModeValueForClosedShifts={setHcpPaymentModeValueForClosedShifts}
                isFacilityListLoading={isFacilityListLoading}
                dateRange={dateRange}
                shiftRequirementType={shiftRequirementType}
                setRequirementType={setRequirementType}
                setDateRange={setDateRange}
                paymentDateRange={paymentDateRange}
                setPaymentDateRange={setPaymentDateRange}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                regions={regions}
                selectedHcps={selectedHcps}
                setSelectedHcps={setSelectedHcps}
                selectedTimeTypes={selectedTimeTypes}
                setSelectedTimeTypes={setSelectedTimeTypes}
                selectedFacilities={selectedFacilities}
                setSelectedFacilities={setSelectedFacilities}
                setIsHcpSentHome={setIsHcpSentHome}
                isHcpSentHome={isHcpSentHome}
                noStatus={true}
                isClosed={true}
                resetFilters={resetFilters}
                facilityList={facilityList}
                hcpTypes={hcpTypes}
                isHcpPayment={isHcpPayment}
                setIsHcpPayment={setIsHcpPayment}
                selectedApprovedHcps={selectedApprovedHcps}
                setSelectedApprovedHcps={setSelectedApprovedHcps}
                selectedSalaryCreditTypes={selectedSalaryCreditTypes}
                setSelectedSalaryCreditTypes={setSelectedSalaryCreditTypes}
            />

            <div className="custom-border pdd-10 pdd-top-0 pdd-bottom-20 mrg-top-0">
                <div className="mrg-left-5 filter">
                    <div className="table-header-actions mrg-bottom-10">
                        <div className="d-flex">
                            <div className="d-flex position-relative">
                                {!list?.table.filter.search ? (
                                    <div className={"search_icon"}>
                                        <SearchRounded/>
                                    </div>
                                ) : (
                                    <div className={"search_icon"}>
                                        <ClearIcon
                                            onClick={(event) => {
                                                if (list && list.table) {
                                                    list.table.filter.search = "";
                                                    setShiftSearch("");
                                                    list.table.reload();
                                                    // list?.table.pageEvent(0)
                                                }
                                            }}
                                            id="clear_shift_search"
                                        />
                                    </div>
                                )}
                                <div>
                                    <CssTextField
                                        defaultValue={""}
                                        className="search-cursor searchField"
                                        id="input_search_shift"
                                        onChange={(event) => {
                                            if (list && list.table) {
                                                list.table.filter.search = event.target.value;
                                                setShiftSearch(event.target.value);
                                                list.table.reload();
                                                // list?.table.pageEvent(0)
                                            }
                                        }}
                                        value={list?.table.filter.search}
                                        variant={"outlined"}
                                        size={"small"}
                                        type={"text"}
                                        placeholder={"Search Shift"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="actions d-flex">
                            <div className={'mrg-right-20'}>
                                <Tooltip title={"Pending Concern"}>
                                    <Button variant={"contained"} color={"primary"}
                                            disabled={!(isPendingConcern?.label === "No") || (selectedCount === -1)}
                                            onClick={openPendingConcern}>
                                        &nbsp;&nbsp;Pending Concern&nbsp;&nbsp;
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className="paydate-btn">
                                <Button
                                    onClick={openPaymentDetails}
                                    disabled={(selectedCount === -1) || (isTheSelectedShiftPendingConcern)}
                                    className={`${(selectedCount === -1) || (isTheSelectedShiftPendingConcern) ? "btn-disabled" : ""}`}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Add Payment
                                </Button>
                            </div>
                            <div className="mrg-left-20">
                                <Tooltip title={"Cancel Closed Shift"}>
									<span>
										<Button
                                            variant={"contained"}
                                            color={"primary"}
                                            disabled={selectedCount === -1}
                                            onClick={openRejectShift}
                                        >
											&nbsp;&nbsp;Cancel Shift&nbsp;&nbsp;
										</Button>
									</span>
                                </Tooltip>
                            </div>
                            <div className="mrg-left-20">
                                <Tooltip title="Download Shifts List">
                                    <Button variant={"contained"} color="primary" onClick={handleDownload}
                                            className={!isDownloading ? "" : "has-loading-spinner"}
                                            disabled={isDownloading}>
                                        &nbsp;Download
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="actions"></div>
                </div>
                {list && list.table && (
                    <>
                        <TableContainer component={Paper} className={"table-responsive"}>
                            <Table stickyHeader className="mat-table table shifts-closed-list-table">
                                <TableHead className={"mat-thead"}>
                                    <TableRow className={"mat-tr"}>
                                        <TableCell padding="checkbox" className="mat-th">
                                            <Checkbox
                                                onChange={(event) => handleSelectAll(event)}
                                                checked={isAllselected}
                                                id={"select-all-cb"}
                                            />
                                        </TableCell>
                                        {list?.table.matColumns.map((column: any, columnIndex: any) => (
                                            <TableCell
                                                className={column === "Actions" ? "mat-th mat-th-sticky" : "mat-th"}
                                                key={"header-col-" + columnIndex}
                                            >
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={"mat-tbody"}>
                                    {!list.table._isDataLoading && list.table?.data.length === 0 && (
                                        <NoDataCardComponent tableCellCount={list.table.matColumns.length}/>
                                    )}
                                    {list?.table.data.map((row: any, rowIndex: any) => {
                                        const shift_date = CommonService.getUtcDate(row["shift_date"]);
                                        const payment_date = row["hcp_pay_date"] ? moment(row["hcp_pay_date"]).format("MM-DD-YYYY") : "NA";
                                        const isItemSelected = isSelected(row["_id"]);
                                        const isFacilityApproved = row?.hasOwnProperty('is_facility_approved') && row["is_facility_approved"];
                                        const isTimeSheetUploaded = row?.hasOwnProperty('is_timesheet_uploaded') && row["is_timesheet_uploaded"];
                                        const isHcpSentHome = row?.hasOwnProperty('is_sent_home') ? (row["is_sent_home"] === true ? "Yes" : "No") : "No";
                                        const isPendingConcern = row?.hasOwnProperty('is_pending_concern') && row["is_pending_concern"];
                                        const missingDocuments = row["document_status"]?.filter((doc: any) => doc.is_required && !doc.is_uploaded);
                                        const checkinTime: any = row["time_breakup"]?.check_in_time ? CommonService.startTime(row["time_breakup"]?.check_in_time) : "";
                                        const checkOutTime: any = row["time_breakup"]?.check_out_time ? CommonService.startTime(row["time_breakup"]?.check_out_time) : "";
                                        const breakInTime: any = row["time_breakup"]?.break_timings?.length > 0 ? CommonService.startTime(row["time_breakup"]?.break_timings[0]?.break_in_time) : "";
                                        const breakOutTime: any = row["time_breakup"]?.break_timings?.length > 0 ? CommonService.startTime(row["time_breakup"]?.break_timings[0]?.break_out_time) : "";

                                        //   const clockIn = CommonService.calculateEarlyClockInTime(row["time_breakup"]);

                                        const result = CommonService.compareCheckIn(row["expected"], row["time_breakup"]);
                                        const checkOutResult = CommonService.compareCheckOut(row["expected"], row["time_breakup"])

                                        return (
                                            <TableRow role="checkbox" tabIndex={-1} key={"row-" + rowIndex}
                                                      className="mat-tr">
                                                <TableCell className="mat-td mat-td-checkbox">
                                                    <Checkbox
                                                        id={"cb_" + rowIndex}
                                                        disabled={row["status"] === "cancelled"}
                                                        checked={isItemSelected}
                                                        onChange={(event) => {
                                                            handleSelectShifts(event, row["_id"], row["is_pending_concern"]);
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell className="mat-td">
                                                    <div style={{
                                                        display: "flex",
                                                        minWidth: "50px",
                                                        maxWidth: "80px"
                                                    }}>
                                                        {(missingDocuments && missingDocuments?.length > 0) &&
                                                            <Tooltip arrow={true} title={<div>
                                                                <div className="missing-documents-container">
                                                                    <div className="tooltip">
                                                                        <h3>Missing staff documents</h3>
                                                                        <ul>
                                                                            {missingDocuments.map((doc: any) => (
                                                                                <li key={doc.key}>{doc.title}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>}>
                                                                <Button style={{
                                                                    color: "red",
                                                                    cursor: "pointer",
                                                                }} to={`/hcp/edit/` + row["hcp_details"]?._id}
                                                                        component={Link}>{(missingDocuments && missingDocuments?.length > 0) &&
                                                                    <WarningIcon/>}</Button>
                                                            </Tooltip>}
                                                    </div>
                                                </TableCell>
                                                <TableCell className="mat-td ">
                                                    <div className={'d-flex'}>
                                                        {isPendingConcern &&
                                                            <div className={'mrg-right-10'}>
                                                                <WarningIcon className={'mrg-top-5 mrg-right-5'}
                                                                             color={'error'}/>
                                                            </div>
                                                        }
                                                        {/*<div style={{*/}
                                                        {/*    display: "flex",*/}
                                                        {/*    alignItems: "center",*/}
                                                        {/*    justifyContent: "center"*/}
                                                        {/*}}>*/}
                                                        {/*    {shift_date + "_" + row["shift_type"] + "_" + row["hcp_details"]?.first_name}&nbsp;{row["hcp_details"]?.last_name + "_" + row["facility"]?.name}*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </TableCell>
                                                <TableCell className="mat-td mat-td-shift-date">
                                                    {/*{clockIn}*/}
                                                    <Tooltip
                                                        title={`${result.isBefore ? `Staff has clocked in earlier than shift start time by ${result.hours} hour(s) and ${result.minutes} minute(s).` : "Staff has clocked in on time or after the shift start"}`}>
                                                        <p style={{color: `${result.isBefore ? "red" : ""}`}}>
                                                            {checkinTime}
                                                        </p>
                                                    </Tooltip>

                                                </TableCell>
                                                <TableCell className="mat-td mat-td-shift-date">
                                                    {breakInTime}
                                                </TableCell>
                                                <TableCell className="mat-td mat-td-shift-date">
                                                    {breakOutTime}
                                                </TableCell>
                                                <TableCell className="mat-td mat-td-shift-date">
                                                    {/*{clockIn}*/}
                                                    <Tooltip
                                                        title={`${checkOutResult.isAfter ? `Staff has clocked out later than shift end time by ${checkOutResult.hours} hour(s) and ${checkOutResult.minutes} minute(s).` : "Staff has clocked out  on time or before the shift end"}`}>
                                                        <p style={{color: `${checkOutResult.isAfter ? "red" : ""}`}}>
                                                            {checkOutTime}
                                                        </p>
                                                    </Tooltip>

                                                </TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-facility-name">{row["facility"]?.name}</TableCell>
                                                <TableCell className="mat-td mat-td-hcp-name">
                                                    {row["hcp_details"]?.first_name}&nbsp;{row["hcp_details"]?.last_name}
                                                </TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-payment-date">{payment_date}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-completed-date">{shift_date}</TableCell>


                                                <TableCell
                                                    className="mat-td mat-td-hcp-type">{row["hcp_type"]}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-shift-type">{row["shift_type"]}</TableCell>
                                                <TableCell className="mat-td mat-td-is-hcp-sent-home"><span
                                                    className={`${isHcpSentHome === 'Yes' ? 'success' : 'danger'}`}>{isHcpSentHome}</span></TableCell>

                                                <TableCell className="mat-td mat-td-is-hcp-paid ">
													<span
                                                        className={`${row["is_hcp_paid"] === true ? "success" : "danger"}`}>
														{"is_hcp_paid" in row ? (row["is_hcp_paid"] === true ? "Yes" : "No") : "NA"}
													</span>
                                                </TableCell>
                                                <TableCell className="mat-td mat-td-timesheet">{isTimeSheetUploaded ?
                                                    <Checkbox checked={isTimeSheetUploaded}/> :
                                                    <Checkbox checked={Boolean(isTimeSheetUploaded)}/>}</TableCell>
                                                <TableCell
                                                    className="mat-td mat-td-is-facility-approved">{isFacilityApproved ?
                                                    <Checkbox checked={isFacilityApproved}/> :
                                                    <Checkbox checked={Boolean(isFacilityApproved)}/>}</TableCell>

                                                <TableCell className="mat-td mat-td-sticky mat-td-actions">
                                                    <Tooltip title={`${row["title"]} shift view details`}>
                                                        <Link
                                                            to={"/closedShifts/view/" + row["_id"]}
                                                            className="info-link"
                                                            id={"link_hospital_details" + rowIndex}
                                                        >
                                                            {"View Details"}
                                                        </Link>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={list.table.pagination.pageSizeOptions}
                            component="div"
                            count={list?.table.pagination.totalItems}
                            rowsPerPage={list?.table.pagination.pageSize}
                            page={list?.table.pagination.pageIndex}
                            onPageChange={(event, page) => list.table.pageEvent(page)}
                            onRowsPerPageChange={(event) => {
                                setPageSizeIndex(event.target.value);
                                list.table?.pageEvent(0, +event.target.value);
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default ClosedShiftsScreen;
