import {Avatar, Button, Checkbox, MenuItem, TextField, Tooltip} from "@material-ui/core";
import {Field, Form, Formik, FormikHelpers} from "formik";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import * as Yup from "yup";
import DialogComponent from "../../../../components/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import {ENV} from "../../../../constants";
import {isHCPPaid as hcpPaidOptions} from "../../../../constants/data";
import {ApiService, CommonService, Communications} from "../../../../helpers";
import EditShiftDetailsDialog from "../../editShiftDetailsDialog/EditShiftDetailsDialog";
import RejectShiftComponent from "../../rejectShift/RejectShiftComponent";
import {showDropDownBelowField} from "../../shiftMaster/view/ShiftMasterValidations";
import ShiftTimeline from "../../timeline/ShiftTimeline";
import ChangeShiftHCPDialog from "../changeShiftHCPDialog/ChangeShiftHCPDialog";
import {TsFileUploadConfig, TsFileUploadWrapperClass} from "../../../../classes/ts-file-upload-wrapper.class";
import CustomPreviewFile from "../../../../components/shared/CustomPreviewFile";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FileDropZoneComponent from "../../../../components/core/FileDropZoneComponent";
import "./ApprovedShiftsViewScreen.scss";
import ApprovedCancelledComponentDetails
    from "../../approved-cancelled-shift-details/ShiftDetailsApprovedCancelledComponent";

const ApprovedShiftsViewScreen = () => {
    const param = useParams<any>();
    const {id} = param;
    const [basicDetails, setBasicDetails] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isRejectShiftOpen, setRejectShiftOpen] = useState<boolean>(false);
    const [isChangeHCPOpen, setIsChangeHCPOpen] = useState<boolean>(false);
    const [isFacilityConfirm, setIsFacilityConfirm] = useState<boolean>(false);


    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isHCPBilled, setIsHCPBilled] = useState<boolean | null>(null);
    const [isEditShiftOpen, setIsEditShiftOpen] = useState<boolean>(false);

    const [fileUpload, setFileUpload] = useState<{ wrapper: any } | null>(null);
    const [required_attachments, setRequiredAttachments] = useState<any>([{name: "Timesheet", index: -1}]);
    const [open, setOpen] = useState<boolean>(false);
    //const [downloadAttachmentsList, downloadSeAttachmentsList] = useState<any | null>(null);
    const [isAttachhmentsDownloadLoading] = useState<boolean>(false);
    const [attachmentsList, setAttachmentsList] = useState<any>(null);
    const [previewFileData, setPreviewFile] = useState<any | null>(null);
    const [isTimeSheetBeingUpdated, setIsTimeSheetBeingUpdated] = useState<boolean>(false);
    const [isAttachmentsLoading] = useState<boolean>(false);
    const [remarks, setRemarks] = useState('');

    const getShiftDetails = useCallback(() => {
        setIsLoading(true);
        CommonService._api
            .get(ENV.API_URL + "shift/" + id)
            .then((resp) => {
                setBasicDetails(resp.data);
                setIsHCPBilled(resp.data?.facility_billed_details?.is_billed);
                setIsFacilityConfirm(resp?.data?.is_facility_approved);
                setRemarks(resp?.data?.remarks);
                setIsLoading(false);
                if (resp?.data?.timesheet_details?.url !== undefined) {
                    setAttachmentsList([resp?.data?.timesheet_details]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id]);

    useEffect(() => {
        getShiftDetails();
    }, [getShiftDetails]);
    const paymentInitialState = {
        facility_billed_details: {
            is_billed: basicDetails?.facility_billed_details?.hasOwnProperty("is_billed")
                ? basicDetails?.facility_billed_details?.is_billed
                : "",
            invoice_date: basicDetails?.facility_billed_details?.invoice_date ?? null,
            comments: basicDetails?.facility_billed_details?.hasOwnProperty("comments")
                ? basicDetails?.facility_billed_details?.comments
                : "",
        },

    };

    const getUpdatedShiftData = useCallback(() => {
        return new Promise((resolve, reject) => {
            CommonService._api
                .get(ENV.API_URL + "shift/" + id)
                .then((resp) => {
                    setBasicDetails(resp?.data);
                    resolve(null);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                    setIsLoading(false);
                });
        });
    }, [id]);

    const confirmOpenEditShift = useCallback(() => {
        getUpdatedShiftData().then((res) => setIsEditShiftOpen(false));
    }, [getUpdatedShiftData]);

    const paymentValidation = Yup.object({
        facility_billed_details: Yup.object({
            is_billed: Yup.mixed().nullable(),
            invoice_date: Yup.date()
                .typeError("Required")
                .when("is_billed", {
                    is: true,
                    then: Yup.date().required("Required").nullable(),
                })
                .when("is_billed", {
                    is: false,
                    then: Yup.date().nullable(),
                }),
            comments: Yup.string().nullable(),
        })
    });


    const openRejectShift = useCallback(() => {
        setRejectShiftOpen(true);
    }, []);

    const cancelRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
    }, []);

    const confirmRejectShift = useCallback(() => {
        setRejectShiftOpen(false);
        getShiftDetails();
    }, [getShiftDetails]);

    const openChangeHCP = useCallback(() => {
        setIsChangeHCPOpen(true);
    }, []);

    const cancelChangeHCP = useCallback(() => {
        setIsChangeHCPOpen(false);
    }, []);

    const confirmChangeHCP = useCallback(() => {
        getShiftDetails();
        setIsChangeHCPOpen(false);
    }, [getShiftDetails]);


    const previewFile = useCallback(
        (index: any, type: any) => {
            setOpen(true);
            if (type === "local") {
                setPreviewFile(fileUpload?.wrapper[0]);
            } else {
                setPreviewFile(attachmentsList[index]);
            }

        },
        [attachmentsList, fileUpload?.wrapper]
    );

    const handleDeleteTimesheet = useCallback(() => {
        if (attachmentsList.length > 0) {
            const shiftId = basicDetails?._id;
            const file_key = attachmentsList[0].file_key;
            ApiService.delete(ENV.API_URL + `shift/${shiftId}/timesheet`, {
                file_key,
            })
                .then((res) => {
                    CommonService.showToast(res?.msg || `Timesheet deleted successfully,`, "success");
                    setAttachmentsList([]);
                })
                .catch((err) => {
                    CommonService.showToast(err?.message || `Error,`, "error");
                    console.log(err);
                });
        }
    }, [attachmentsList, basicDetails?._id]);

    const cancelPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    const confirmPreviewFile = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {
        Communications.pageTitleSubject.next("Approved");
        Communications.pageBackButtonSubject.next("/approvedShifts/list");
    }, []);
    const handleConfirmationFromFacility = useCallback(() => {
        return new Promise((resolve, reject) => {
            ApiService.put(ENV.API_URL + "shift/" + id, {
                is_facility_approved: isFacilityConfirm,
            })
                .then((res: any) => {
                    resolve(null);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }, [id, isFacilityConfirm]);

    const handleGetUrlForUpload = useCallback(() => {
        return new Promise((resolve, reject) => {
            const file = fileUpload?.wrapper[0]?.file;
            const formData = new FormData();
            if (file) {
                formData.append("attachment", file);
                formData.append("attachment_type", fileUpload?.wrapper[0]?.extraPayload?.file_type);
            }
            if (fileUpload?.wrapper.length > 0) {
                setIsTimeSheetBeingUpdated(true);
                CommonService._api
                    .upload(ENV.API_URL + "shift/" + id + "/timesheet", formData)
                    .then((resp) => {
                        resolve(resp);
                        setIsTimeSheetBeingUpdated(false);
                        CommonService.showToast(resp?.msg || "attachment uploaded", "success");
                    })
                    .catch((err) => {
                        resolve(err);
                        CommonService.showToast(err?.error || "Error", "error");
                        setIsTimeSheetBeingUpdated(false);
                    });
            } else {
                resolve(null);
            }
        });
    }, [fileUpload?.wrapper, id]);


    const openEditShift = useCallback(() => {
        setIsEditShiftOpen(true);
    }, []);

    const cancelEditShift = useCallback(() => {
        setIsEditShiftOpen(false);
    }, []);

    const onAdd = (payload: any, {setSubmitting, setErrors, resetForm}: FormikHelpers<any>) => {
        setIsSubmitting(true);

        Promise.all([handleConfirmationFromFacility(), handleShiftDataEdit(payload), handleGetUrlForUpload()]).then((res: any) => {
            CommonService.showToast(res?.msg || "Success", "success");
            setIsSubmitting(false);
            resetForm();
            history.push("/approvedShifts/list");
        }).catch((err) => {
            CommonService.showToast(err?.error || "Error", "error");
            setIsSubmitting(false);
        });

    };
    const handleFacilityConfirmation = (e: any) => {
        setIsFacilityConfirm(e.target.checked);
    };

    const handleShiftDataEdit = (payload: any) => {
        const {facility_billed_details} = payload;
        const {invoice_date, is_billed, comments: facility_comments} = facility_billed_details;

        setIsSubmitting(true);
        return new Promise((resolve, reject) => {
            ApiService.put(ENV.API_URL + "shift/" + id, {
                facility_comments,
                invoice_date: invoice_date ? moment(invoice_date).format("YYYY-MM-DD") : null,
                is_billed,
                remarks
            })
                .then((res: any) => {
                    setIsSubmitting(false);
                    resolve(res);
                })
                .catch((err) => {
                    console.log(err);
                    setIsSubmitting(false);
                    reject(err);
                });
        });
    };

    const OnFileSelected = (files: File[], index: any) => {
        if (required_attachments[index]) {
            required_attachments[index].index = fileUpload?.wrapper?.length || 0;
            setRequiredAttachments([...required_attachments]);
        }
        for (let file of files) {
            // console.log(file)
            const uploadConfig: TsFileUploadConfig = {
                file: file,
                fileFieldName: "Data",
                uploadUrl: ENV.API_URL + "facility/add",
                allowed_types: ["jpg", "png", "csv", "pdf", "jpeg"],
                extraPayload: {file_type: required_attachments[index]?.name},
            };
            const uploadWrapper = new TsFileUploadWrapperClass(
                uploadConfig,
                CommonService._api,
                (state: { wrapper: TsFileUploadWrapperClass }) => {
                    // console.log(state);
                    setFileUpload((prevState) => {
                        if (prevState) {
                            const index = prevState?.wrapper.findIndex((value: any) => value.uploadId === state.wrapper.uploadId);
                            prevState.wrapper[index] = state.wrapper;
                            return {wrapper: prevState.wrapper};
                        }
                        return prevState;
                    });
                }
            );
            uploadWrapper.onError = (err, heading) => {
                // console.error(err, heading);
                if (heading) {
                    CommonService.showToast(err, "error");
                }
            };
            uploadWrapper.onSuccess = (resp) => {
                console.log(resp);
                if (resp && resp.success) {
                    CommonService.showToast(resp.msg || resp.error, "success");
                }
            };
            uploadWrapper.onProgress = (progress) => {
            };
            setFileUpload((prevState) => {
                let state: TsFileUploadWrapperClass[] = [];
                if (prevState) {
                    state = prevState?.wrapper;
                }
                const newState = [...state, uploadWrapper];
                return {wrapper: newState};
            });
        }
    };

    const deleteFile = (temp: any) => {
        let data = fileUpload?.wrapper.filter((_: any, index: any) => index !== temp);
        if (required_attachments[temp]) {
            required_attachments[temp].index = -1;
            setRequiredAttachments([...required_attachments]);
        }
        setFileUpload((prevState) => {
            return {wrapper: [...data]};
        });
    };

    if (isLoading || isAttachmentsLoading || isAttachhmentsDownloadLoading) {
        return <LoaderComponent/>;
    }


    return (
        <div className="pending-shifts-view screen crud-layout pdd-30">
            <DialogComponent open={isRejectShiftOpen} cancel={cancelRejectShift}>
                <RejectShiftComponent cancel={cancelRejectShift} confirm={confirmRejectShift} selectedShifts={null}
                                      isMultipleReasons={true}/>
            </DialogComponent>

            <DialogComponent open={open} cancel={cancelPreviewFile} class="preview-content">
                <CustomPreviewFile cancel={cancelPreviewFile} confirm={confirmPreviewFile}
                                   previewData={previewFileData}/>
            </DialogComponent>

            <DialogComponent open={isChangeHCPOpen} cancel={cancelChangeHCP} maxWidth="md">
                <ChangeShiftHCPDialog
                    cancel={cancelChangeHCP}
                    confirm={confirmChangeHCP}
                    selectedHcpType={basicDetails?.hcp_type}
                    shiftId={basicDetails?._id}
                />
            </DialogComponent>

            <DialogComponent open={isEditShiftOpen} cancel={cancelEditShift} maxWidth="sm">
                <EditShiftDetailsDialog
                    shiftDetails={basicDetails}
                    cancel={cancelEditShift}
                    confirm={confirmOpenEditShift}
                    shiftId={id}
                />
            </DialogComponent>

            {!isLoading && (
                <>
                    <div className="header">
                        <div className="filter"></div>
                        <div className="actions">
                            <Tooltip title={"Change Staff"}>
								<span>
									<Button variant={"outlined"} onClick={openChangeHCP} color={"primary"}>
										Change Staff
									</Button>
								</span>
                            </Tooltip>
                            {basicDetails?.status !== "cancelled" ? (
                                <Tooltip title={"Cancel Shift"}>
									<span>
										<Button variant={"contained"} onClick={openRejectShift} color={"primary"}>
											Cancel Shift
										</Button>
									</span>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="pdd-0 custom-border">
                        <div className="d-flex pdd-20 hcp-photo-details-wrapper">
                            <div className="d-flex">
                                <div className="flex-1">
                                    <Avatar alt="user photo" style={{height: "80px", width: "80px"}}>
                                        {basicDetails?.hcp_details?.first_name?.toUpperCase().charAt("0")}
                                    </Avatar>
                                </div>
                                <div className="hcp-name-type">
                                    <h2>
                                        {basicDetails?.hcp_details?.first_name}&nbsp;{basicDetails?.hcp_details?.last_name}
                                    </h2>
                                    <p>{basicDetails?.hcp_details?.hcp_type}</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex hcp-details pdd-bottom-20 custom-border " style={{gap: "20px"}}>
                            <div className="flex-1">
                                <h4>Years Of Experience</h4>
                                <p>
                                    {basicDetails?.hcp_details?.professional_details?.experience ? basicDetails?.hcp_details?.professional_details?.experience + " Years" : (basicDetails?.hcp_details?.professional_details?.experience === 0 ? 0 + " Years" : "N/A")}

                                </p>
                            </div>
                            <div className="flex-1">
                                <h4>Contact Number</h4>
                                <p>{basicDetails?.hcp_details?.contact_number}</p>
                            </div>
                            <div className="flex-1">
                                <h4>Address</h4>
                                <p>
                                    {basicDetails?.hcp_details?.address?.region},&nbsp;{basicDetails?.hcp_details?.address?.city},&nbsp;
                                    {basicDetails?.hcp_details?.address?.state},&nbsp;{basicDetails?.hcp_details?.address?.country},&nbsp;
                                    {basicDetails?.hcp_details?.address?.zip_code}&nbsp;&nbsp;
                                </p>
                            </div>
                            <div className="flex-1">
                                <h4>Email</h4>
                                <p>{basicDetails?.hcp_details?.email}</p>
                            </div>
                            <div className="flex-1">
                                <h4>Staff Rate (hr)</h4>
                                <p>{basicDetails?.hcp_details?.contract_details?.rate_per_hour ?? 0} $</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex facility-details mrg-top-10 custom-border pdd-bottom-0">
                        <div className="flex-1">
                            <h2>{basicDetails?.facility?.name}</h2>
                            <p>
                                {basicDetails?.facility?.address?.street},&nbsp;{basicDetails?.facility?.address?.region},&nbsp;
                                {basicDetails?.facility?.address?.city},&nbsp;{basicDetails?.facility?.address?.country},&nbsp;
                                {basicDetails?.facility?.address?.zip_code}.
                            </p>
                        </div>
                        <div className="flex-1 actions-wrapper">
                            <div className="button">
                                <Tooltip title={`View ${basicDetails?.facility?.facility_name} Details`}>
                                    <Button
                                        component={Link}
                                        color={"primary"}
                                        variant={"outlined"}
                                        to={{
                                            pathname: "/facility/view/" + basicDetails?.facility?._id,
                                            state: {prevPath: "/approvedShifts/view/" + id},
                                        }}
                                    >
                                        View Details
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <ApprovedCancelledComponentDetails basicDetails={basicDetails} openEditShift={openEditShift}/>
                    <div className="mrg-top-10 d-flex custom-border pdd-top-10">
                        <h2>Facility Confirmation</h2>
                        <Checkbox checked={isFacilityConfirm} onChange={handleFacilityConfirmation}/>
                    </div>

                    <Formik initialValues={paymentInitialState} validationSchema={paymentValidation} onSubmit={onAdd}>
                        {({isSubmitting, handleSubmit, isValid, resetForm, setFieldValue, values}) => (
                            <Form id="add-facility-payment-form" className={"payment-name form-holder"}>
                                <div className="mrg-top-10 custom-border pdd-top-10">
                                    <h2>Facility Billed Details</h2>
                                    <div className="input-container">
                                        <Field
                                            required
                                            value={values.facility_billed_details.is_billed}
                                            onChange={(e: any) => {
                                                if (e.target.value === false) {
                                                    setFieldValue("facility_billed_details.invoice_date", null);
                                                }
                                                setIsHCPBilled(e.target.value);
                                                setFieldValue("facility_billed_details.is_billed", e.target.value);
                                            }}
                                            SelectProps={showDropDownBelowField}
                                            select
                                            variant="outlined"
                                            fullWidth
                                            component={TextField}
                                            name="facility_billed_details.is_billed"
                                            id="input_is_billed"
                                            label="Billed/Invoiced"
                                        >
                                            <MenuItem value="">Select </MenuItem>
                                            {hcpPaidOptions.map((item: any, index) => {
                                                return (
                                                    <MenuItem value={item.value} key={index} id={"is_billed"}>
                                                        {item.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Field>

                                        {isHCPBilled && (
                                            <Field
                                                required
                                                value={values.facility_billed_details.invoice_date}
                                                id="input_invoice_date"
                                                variant="outlined"
                                                format="MM/dd/yyyy"
                                                component={TextField}
                                                placeholder="MM/DD/YYYY"
                                                type="date"
                                                fullWidth
                                                autoComplete="off"
                                                InputLabelProps={{shrink: true}}
                                                label="Invoice Date"
                                                name="facility_billed_details.invoice_date"
                                                onChange={(e: any) => setFieldValue("facility_billed_details.invoice_date", e.target.value)}
                                            />
                                        )}
                                    </div>

                                    <Field
                                        value={values.facility_billed_details.comments}
                                        onChange={(e: any) => {
                                            setFieldValue("facility_billed_details.comments", e.target.value);
                                        }}
                                        multiline
                                        variant="outlined"
                                        fullWidth
                                        component={TextField}
                                        name="facility_billed_details.comments"
                                        label="Comments"
                                        id="input_facility_comments"
                                        minRows={3}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>


                    <div className="custom-border mrg-top-40 pdd-top-10">
                        <h2>Remarks</h2>
                        <TextField
                            value={remarks}
                            onChange={(e: any) => {
                                setRemarks(e.target.value);
                            }}
                            multiline
                            variant="outlined"
                            fullWidth
                            name="remarks"
                            id="input_facility_remarks"
                            minRows={3}
                        />
                    </div>

                    <div className="mrg-top-10 custom-border pdd-top-10">
                        <div className="shift-name-requested">
                            <div className="d-flex">
                                <h2 className="flex-1">Shift Timings</h2>
                                <h4 className="hcp-rate">
                                    Staff Rate:
                                    <span className="mrg-left-10 ">
										{basicDetails?.hcp_details?.contract_details?.rate_per_hour ?? 0} $
									</span>
                                </h4>
                            </div>
                            <div className="d-flex shift-date-time">
                                <div className="flex-1">
                                    <h3>Attended On</h3>
                                    <p className="attended-date ">
                                        {basicDetails?.actuals?.shift_start_time
                                            ? moment(basicDetails?.actuals?.shift_start_time).utcOffset(0, false).format("MM-DD-YYYY")
                                            : "--"}
                                    </p>
                                </div>
                                <div className="flex-1 flex-baseline">
                                    <h3>Manual Break Time</h3>
                                    <p className="shift-duration">
                                        {basicDetails?.time_breakup.break_period ? basicDetails?.time_breakup.break_period : "NA"}
                                    </p>
                                </div>
                                <div className="flex-1 flex-baseline">
                                </div>
                                <div className="flex-1 flex-baseline">
                                </div>
                                <div className="flex-1 flex-baseline">
                                </div>
                            </div>
                            <div className="pdd-bottom-20">
                                <ShiftTimeline timeBreakup={basicDetails?.time_breakup} basicDetails={basicDetails}/>
                            </div>
                        </div>
                    </div>

                    <div className="mrg-top-10 custom-border pdd-top-10">
                        <div className="mrg-top-20">
                            {attachmentsList?.length > 0 ? (
                                <>
                                    <h3>Attachment:</h3>
                                    <div className="d-flex" style={{gap: "50px"}}>
                                        {attachmentsList?.map((item: any, index: any) => {
                                            return (
                                                <div className="attachments">
                                                    <div>
                                                        <p className="">{item?.attachment_type}</p>
                                                        <Tooltip title="Preview Timesheet Form">
                                                            {
                                                                <InsertDriveFileIcon
                                                                    color={"primary"}
                                                                    className="file-icon"
                                                                    onClick={() => previewFile(index, "api")}
                                                                    style={{cursor: "pointer"}}
                                                                />
                                                            }
                                                        </Tooltip>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Tooltip title="Download Timesheet Form">
                                                            <p onClick={() => previewFile(index, "api")}
                                                               className="file-actions">
                                                                Preview
                                                            </p>
                                                        </Tooltip>
                                                        <Tooltip title="Download Timesheet Form">
                                                            <div
                                                                className="file-actions mrg-left-10" onClick={() => {
                                                                CommonService.handleDownloadAnyAttachment(item?.file_key);
                                                            }}>
                                                                Download
                                                            </div>
                                                        </Tooltip>
                                                        <Tooltip title="Download Timesheet Form">
														<span className="file-actions mrg-left-10"
                                                              onClick={handleDeleteTimesheet}>
															Delete
														</span>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            ) : (
                                <>

                                    <h3 className="mrg-top-0">Attachment:</h3>
                                    <div className="d-flex" style={{gap: "50px"}}>
                                        {required_attachments?.map((item: any, index: any) => {
                                            if (item.index !== -1) {
                                                return (
                                                    <>
                                                        <div className="attachments">
                                                            <div className="custom_file mrg-top-0">
                                                                <h3 className="mrg-top-20 mrg-bottom-0 file_name file_attachment_title">
                                                                    {" "}
                                                                    {fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload?.file_type}
                                                                </h3>
                                                                <div className="mrg-top-15">
                                                                    <InsertDriveFileIcon color={"primary"}
                                                                                         className="file-icon"/>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex file_actions">
                                                                <Tooltip
                                                                    title={`View ${
                                                                        fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload?.file_type
                                                                    }`}
                                                                >
                                                                    <p
                                                                        style={{cursor: "pointer", width: "50px"}}
                                                                        className={"delete-cdhp mrg-top-0"}
                                                                        onClick={() => previewFile(index, "local")}
                                                                    >
                                                                        View
                                                                    </p>
                                                                </Tooltip>
                                                                <Tooltip
                                                                    title={`Delete ${
                                                                        fileUpload?.wrapper[required_attachments[index]?.index]?.extraPayload?.file_type
                                                                    }`}
                                                                >
                                                                    <p
                                                                        style={{cursor: "pointer", width: "50px"}}
                                                                        className={"delete-cdhp mrg-top-0"}
                                                                        onClick={() => deleteFile(index)}
                                                                    >
                                                                        Delete
                                                                    </p>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            } else {
                                                return (
                                                    <div className="attachments">
                                                        <div className="">
                                                            <h3 className="attachement_name file_attachment_title">{item?.name}</h3>
                                                            <Tooltip title={`Upload ${item?.name}`}>
                                                                <div>
                                                                    <FileDropZoneComponent
                                                                        OnFileSelected={(item) => OnFileSelected(item, index)}
                                                                        allowedTypes={".pdf"}
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>

                                </>
                            )}
                        </div>
                    </div>


                    <div className="action-btn-grp">
                        <Button
                            size="large"
                            className={isSubmitting || isTimeSheetBeingUpdated ? "has-loading-spinner" : ""}
                            disabled={isSubmitting || isTimeSheetBeingUpdated}
                            form="add-facility-payment-form"
                            variant="contained"
                            color="primary"
                            type="submit"
                            id="btn_hcp_add_education_submit"
                        >
                            {isSubmitting ? "Saving" : "Save"}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default ApprovedShiftsViewScreen;
