import * as Yup from "yup";

export interface FacilityItemEditType {
    uid: string;
    name: string;
    short_name: string;
    business_name: string;
    email?: string;
    contact_number?: string;
    extension_number: string;
    parent_company: any;
    website_url?: string;
    timezone?: string;
    about?: string;
    address?: {
        street: string;
        city: string;
        state: string;
        region: string;
        country: string;
        zip_code: string;
    };
    hourly_base_rates?: {
        lpn_lvn?: number;
        lna?: number;
        cna_lna?: number;
        cna?: number;
        lvn?: number;
        lpn?: number;
        rn?: number;
        care_giver?: number;
        med_tech?: number;
        spd_tech?: number;
        dsp: number,
        pss: number,
        crma: number,
        rn_supervisor: number,
        caregiver_dsp?: number;
        caregiver_pss: number,
        caregiver_crma: number,
        holiday?: number;
        hazard?: number;
        rn_speciality1?: number;
        rn_speciality2?: number;
        rn_travel?: number;
        emt?: number;
        monitor_tech?: number;
        orst?: number;
        physical_therapist?: number;
        chha?: number;
        cna_chha?: number;
        occupational_therapist?: number;
        radiology_tech?: number;
        cls?: number;
        slp?: number;
        scrub_tech?: number;
        medical_assistant: number,
        marriage_family_therapist: number,
        clinical_social_worker: number,
        psychologist: number,
        pharmacist: number,
        pharmacy_technician: number,
        evaluation?: number,
        start_of_care?: number,
        rn_routine_vist?: number,
        lvn_routine_vist?: number,
        ot_pt_st_routine_vist?: number,
        ota_pta_sta_routine_vist?: number,
        discharge?: number,
        recertification?: number,
        resumption_of_care?: number,
        bsw?: number;
        cna_routine_vist?: number,
        caregiver_chha_routine_vist?: number,
    };
    diff_rates?: {
        pm?: number;
        noc?: number;
        weekend?: number;
    };
    conditional_rates: {
        overtime?: {
            hours?: number;
            rate?: number;
        };
        rush?: {
            hours?: number;
            rate?: number;
        };
        cancellation_before?: {
            hours?: number;
            rate?: number;
        };
        shift_early_completion?: {
            hours?: number;
            rate?: number;
        };
    };

    location: any;
    coordinates?: any;
}

//const URL = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;


export const facilityFormEditValidation = Yup.object({
    uid: Yup.string().typeError(" must be a text").required("Required").min(3, "min 3 letters").trim("empty space not allowed").required("Required"),
    name: Yup.string()
        .typeError(" must be a text")
        .min(3, "min 3 letters")
        .max(255, "max limit 255")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .trim("empty space not allowed")
        .required("Required"),
    short_name: Yup.string()
        .typeError(" must be a text")
        .min(3, "min 3 letters")
        .max(30, "max limit 30")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .trim("empty space not allowed")
        .required("Required"),
    business_name: Yup.string()
        .typeError(" must be a text")
        .min(3, "min 3 letters")
        .max(255, "max limit 255")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .trim("empty space not allowed"),
    email: Yup.string().typeError(" must be a text").email("invalid").max(200, "max limit 200"),
    contact_number: Yup.string().min(12, "min 10 digits").required("Required"),
    extension_number: Yup.string()
        .matches(/^[0-9]+$/, "must be number")
        .trim("empty space not allowed")
        .min(1, "min 1 digit")
        .max(10, "max 10 digits"),
    website_url: Yup.string()
        .typeError(" must be a text")
        .max(200, "max limit 200"),
    timezone: Yup.number().typeError(" must be a number").required("Required"),
    about: Yup.string().typeError(" must be a text").max(750, "max limit 750").trim("empty space not allowed"),
    address: Yup.object({
        street: Yup.string().typeError(" must be a text").min(3, "min 3 letters").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        city: Yup.string().typeError(" must be a text").min(2, "min 2 letters").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        state: Yup.string().typeError(" must be a text").min(2, "min 2 letters").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        region: Yup.string().typeError(" must be a text").min(2, "min 2 letters").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        country: Yup.string().typeError(" must be a text").min(2, "min 2 letters").max(30, "max limit 30").trim("empty space not allowed").required("Required"),
        zip_code: Yup.string()
            .typeError(" must be a text")
            .matches(/^[0-9]+$/, "Must be only digits")
            .trim("empty space not allowed")
            .min(5, "min 5 digits")
            .max(6, "max 6 digits allowed")
            .required("Required"),
    }),
    hourly_base_rates: Yup.object({
        lpn_lvn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        lna: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna_lna: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        lvn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        medical_assistant: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        marriage_family_therapist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        clinical_social_worker: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        psychologist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        pharmacist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_chha_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        pharmacy_technician: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        bsw: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),

        cls: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        care_giver: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        med_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        spd_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        dsp: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        pss: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        crma: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_supervisor: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_dsp: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_pss: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        caregiver_crma: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        holiday: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        hazard: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required")
            .nullable(),
        lpn: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_speciality1: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_speciality2: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_travel: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        emt: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        monitor_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        orst: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        radiology_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        occupational_therapist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        physical_therapist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        chha: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        cna_chha: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        slp: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        scrub_tech: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        evaluation: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        start_of_care: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        rn_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        lvn_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        ot_pt_st_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        ota_pta_sta_routine_vist: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        discharge: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        recertification: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
        resumption_of_care: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .nullable(),
    }),
    diff_rates: Yup.object({
        pm: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required"),
        noc: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required"),
        weekend: Yup.string()
            .typeError(" must be valid")
            .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
            .trim("empty space not allowed")
            .max(10, "max 10 digits allowed")
            .required("Required"),
    }),
    conditional_rates: Yup.object({
        overtime: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
        rush: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
        cancellation_before: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
        shift_early_completion: Yup.object({
            hours: Yup.string()
                .typeError(" must be valid")
                .matches(/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
            rate: Yup.string()
                .typeError(" must be valid")
                .matches(/^\d{1,3}(\.$|\.\d{1,3}$|$)/, "invalid")
                .trim("empty space not allowed")
                .max(10, "max 10 digits allowed")
                .nullable(),
        }),
    }),

    // location: Yup.object({
    //   coordinates: Yup.object({
    //     longitude: Yup.number().typeError("must be a number").required("Required"),
    //     latitude: Yup.number().typeError("must be a number").required("Required"),
    //   }),
    // }),
    location: Yup.object().shape({
        coordinates: Yup.array()
            .of(Yup.number())
            .min(2, 'Location coordinates must have at least 2 values')
            .max(2, 'Location coordinates must have at most 2 values')
            .required('Location coordinates are required'),
        type: Yup.string().required('Location type is required'),
    }),
});
